import React, { useEffect } from "react";
import { Route, Routes, BrowserRouter, Navigate } from "react-router-dom";
import Home from "../pages/Home";
import Pricing from "../pages/Pricing";
import Projects from "../pages/Projects";
import Account from "../pages/Account";
import ResetPassword from "../pages/ResetPassword";
import { useSelector } from "react-redux";
import NewProject from "../pages/NewProject";
import SingleProjectPage from "../pages/SingleProjectPage";
import EditProject from "../pages/EditProject";
import ImageEditor from "../pages/ImageEditor";
import NotFound from "../pages/NotFound";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import TermsOfService from "../pages/TermsOfService";
import RefundPolicy from "../pages/RefundPolicy";
import Settings from "../pages/Settings";
import Navigator from "../pages/Navigator";
import Affiliate from "../pages/Affiliate";
import { LIVE_MODE, PRODUCTION } from "./DomainName";

const ProjectRoutes = () => {
  const { token, isLoggedIn } = useSelector((state) => state.authToken);
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/image-editor" element={<ImageEditor/>} />
        <Route path="/account" element={<Account /> } />

{LIVE_MODE ? <>
  
    <Route path="/pricing" element={isLoggedIn ? <Navigator route='pricing'   /> : <Pricing/> } />
        <Route path="/privacyPolicy" element={isLoggedIn ? <Navigator route='privacyPolicy'  />:<PrivacyPolicy/> } />
        <Route path="/termsOfService" element={isLoggedIn ? <Navigator route='termsOfService'  />: <TermsOfService/>} />
        <Route path="/refundPolicy" element={isLoggedIn ? <Navigator route='refundPolicy'  />: <RefundPolicy/>} />
        <Route path="/settings" element={<Navigator route='settings'  /> } />
        <Route path="/resetPassword/:email" element={token ? <ResetPassword /> : <Navigate to="/" replace />} />
        <Route path="/project/:projectId" element={token ?<Navigator route='projects-page'  /> : <Navigate to="/" replace />} />
        <Route path="/new/project/:projectId" element={token ?<Navigator route='projects-page'  /> : <Navigate to="/" replace />} />
        <Route path="/project/edit/:projectId" element={token ? <EditProject /> : <Navigate to="/" replace />} />
        <Route path="/newProject" element={isLoggedIn ? <Navigator route='new-project'  /> : <Navigate to="/account" replace />}/>
        <Route path="/projects" element={isLoggedIn ? <Navigator route='projects'  /> : <Navigate to="/account" replace /> }/>
        <Route path="/:affiliateName" element={<Affiliate/> } />
   
        </>:<>
      
        <Route path="/pricing" element={<Account/>} />
        <Route path="/privacyPolicy" element={<Account/>} />
        <Route path="/termsOfService" element={<Account/>} />
        <Route path="/refundPolicy" element={<Account/>} />
        <Route path="/settings" element={<Account/> } />
        <Route path="/resetPassword/:email" element={<Account/>} />
        <Route path="/project/:projectId" element={<Account/>} />
        <Route path="/new/project/:projectId" element={<Account/>} />
        <Route path="/project/edit/:projectId" element={<Account/>} />
        <Route path="/newProject" element={<Account/>}/>
        <Route path="/projects" element={<Account/>}/>
        <Route path="/:affiliateName" element={<Account/> } />
        </>}

        <Route path="*" element={<NotFound />} />
        <Route path="/notFound" element={<NotFound />} />

      </Routes>
    </BrowserRouter>
  );
};

export default ProjectRoutes;