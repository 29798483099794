import React, { useEffect, useState } from 'react';
import { LockOutlined, MailOutlined } from "@ant-design/icons";
import { Button, Form, Input, Modal, message, Spin } from "antd";
import GoogleLoginBtn from "../components/GoogleLoginBtn";
import FacebookLoginBtn from "../components/FacebookLoginBtn";
import './styles/Signin.css';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import signinImage from '../assets/Frame 81.svg'
import { useDispatch } from "react-redux";
import { setAuthToken, setLoggedIn } from '../redux/AuthToken/Action';
import { startTimer, verifyCode, passwordRules } from '../values/AuthHelpers';
import { DOMAIN_NAME } from '../values/DomainName';
import CustomModal from './CustomModal';
import { useSelector } from 'react-redux';
import { API_SEND_VERIFICATION_EMAIL, API_SIGN_IN, API_VERIFY_CODE_SIGNIN } from '../api/api_authentication';

const Signin = ({ loginEnabler }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [token, setToken] = useState(null);
  const [forgotPasswordModalVisible, setForgotPasswordModalVisible] = useState(false);
  const [showConfirmationCodeForm, setShowConfirmationCodeForm] = useState(false);
  const [forgotPasswordEmail, setForgotPasswordEmail] = useState("");
  const [forgotPasswordEmailError, setForgotPasswordEmailError] = useState("");
  const [timer, setTimer] = useState(4);
  const [timerRunning, setTimerRunning] = useState(false);
  const [showTimer, setShowTimer] = useState(false);
  const [code, setCode] = useState('');
  const [ShowSpinner, setShowSpinner] = useState(false);
  const [remainingTries, setRemainingTries] = useState(3);
  const {isLoggedIn } = useSelector((state) => state.authToken);

  useEffect(() => {
    if (timerRunning) {
      return startTimer(timer, setTimer, setShowTimer, setTimerRunning);
    }
  }, [timer, timerRunning]);

  const onFinish = async (values) => {
    await API_SIGN_IN(setShowSpinner,values.email,values.password,dispatch)
  };

  const enableTimer = () => {
    setShowTimer(true);
    setTimer(4);
    setTimerRunning(true);
  };


  const handleForgotPasswordSubmit = async () => {
    setForgotPasswordModalVisible(false);
    await API_SEND_VERIFICATION_EMAIL(forgotPasswordEmail, setToken, setShowConfirmationCodeForm, enableTimer, true,setShowSpinner);
  };

  const handleConfirmationCode = async () => {
    await API_VERIFY_CODE_SIGNIN(code,token,dispatch,navigate,forgotPasswordEmail,setRemainingTries,setShowSpinner)
  };

  const handleCodeChange = (e) => {
    setCode(e.target.value);
  };

  return (
    <>
      {ShowSpinner && <Spin fullscreen />}
      <div className={`row m-0 justify-content-evenly ${  'mainRow'}`}>
        <div className={`col-12 col-xl-5 col-md-9 col-sm-10 p-0 align-self-center ${  'mainCol'} text-center`}>
          {showConfirmationCodeForm ? (
            <>
              <p className={  'createAccountHeading'}>Enter Code Here</p>
              <div className="row m-0 justify-content-center">
                <div className="col-10 align-self-center">
                  <Form onFinish={handleConfirmationCode} className={  'loginForm'} >
                    <Form.Item name="code" rules={[{ required: true, message: 'Please enter the code received in your email.' }]}>
                      <Input onChange={handleCodeChange} className={  'loginInput1'} placeholder="Enter code..." />
                    </Form.Item>
                    <Button type="primary" htmlType="submit" disabled={!code.trim()} className={  'submitButton'}>Submit</Button>
                  </Form>
                  {showTimer && <p className={  'resendCode'}>Resend Code in: {timer} seconds</p>}
                  {!timerRunning && (
                    <Button onClick={async () => { 
                      setShowSpinner(true);
                      await API_SEND_VERIFICATION_EMAIL(forgotPasswordEmail, setToken, setShowConfirmationCodeForm, enableTimer, true,setShowSpinner);
                      setShowSpinner(false);
                    }} className={  'resendCodeBtn'}>Resend Code</Button>
                  )}
                </div>
              </div>
            </>
          ) : (
            <>
              <p className={  'createAccountHeading'}>Welcome Back!</p>
              <div className="row m-0 pt-2 justify-content-center">
                <div className="col-10 align-self-center">
                  <Form name="login_form" initialValues={{ remember: true }} onFinish={onFinish} className={  'loginForm'} validateTrigger={['onBlur']}>
                    <Form.Item
                      name="email"
                      validateTrigger={['onBlur']}
                      rules={[
                        { required: true, message: 'Enter email' },
                        { type: 'email', message: 'Please enter a valid email address!' }
                      ]}
                      className={  'formItem'}
                    >
                      <Input prefix={<MailOutlined />} placeholder="Email" />
                    </Form.Item>
                    <Form.Item name="password" rules={passwordRules} className={  'formItem2'}>
                      <Input.Password prefix={<LockOutlined />} placeholder="Password" className={  'loginInput2'} onCopy={(e) => e.preventDefault()} />
                    </Form.Item>
                    <p className={  'forgotPasswordBtn'} onClick={()=>setForgotPasswordModalVisible(true)}>Forgot Password?</p>
                    <Form.Item>
                      <Button htmlType="submit" className={  'home_page_btn_filled'}> LOGIN </Button>
                    </Form.Item>
                  </Form>
                  <p className={  'createAccountBtn'} onClick={loginEnabler}>
                    Don't have an account yet? <br/>
                    <b>CREATE A FREE ACCOUNT</b> <span>&rarr;</span>
                    </p>
                  <div className={`${  'or'} mb-4`}>or</div>
                </div>
              </div>
              <div className={`socialLogin row justify-content-center m-0`}>
                <div className="col-8 text-center">
                    <div className="">
                        <FacebookLoginBtn onLoginSuccess={(token) => setToken(token)} />
                        <GoogleLoginBtn onLoginSuccess={(token) => setToken(token)} />
                    </div>
                </div>
                </div>

            </>
          )}
        </div>
        <div className={`col-12 col-xl-5 p-0 d-none d-xl-block align-self-center text-center `}>
           <div className="row m-0 align-self-center">
            <div className="col-12 signinImage_parent align-self-center">
                <span className='star_component'>&#9733;&#9733;&#9733;&#9733;&#9733; 5.0 rating</span>
                <div className='rating_text'>
                As a marketer always looking for new ways to attract potential clients, I have to say that the video creator has been a game-changer for me and my team. This tool has streamlined the process of making personalized videos in bulk, helping us generate more leads effectively. And the video editor feature is great. Thanks!
                </div>
                <div className='name_text'>
                James  Stewart
                </div>
                <div className='position_text'>
                Industry: Digital Marketing
                </div>
            </div>
           </div>
        </div>
      </div>
      <CustomModal title="Forgot Password"  visible={forgotPasswordModalVisible} onOk={handleForgotPasswordSubmit} onCancel={() => setForgotPasswordModalVisible(false)} okText="Send password reminder email">
        <Form>
          <Form.Item name="email" validateTrigger={['onBlur']} rules={[ { required: true, message: 'Please input your Email!' }, { type: 'email', message: 'Please enter a valid email address!' } ]} className={  'formItem'} >
            <Input prefix={<MailOutlined />} placeholder="Email" onChange={(e) => { setForgotPasswordEmail(e.target.value); setForgotPasswordEmailError(""); }} />
          </Form.Item>
          {forgotPasswordEmailError && <p style={{ color: "red" }}>{forgotPasswordEmailError}</p>}
        </Form>
      </CustomModal>
    </>
  );
};

export default Signin;
