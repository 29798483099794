import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import Title from "../components/Title";
import Signin from "../components/Signin";
import { Helmet } from "react-helmet";
import Signup from "../components/Signup";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import Footer from "../components/Footer";

const Account = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { signUp } = location.state || {};
  const [signupState, setsignupState] = useState(signUp);
  const [signinState, setsigninState] = useState(!signUp);
  const { isLoggedIn } = useSelector((state) => state.authToken);

  const signupEnabler = () => {
    setsignupState(true);
    setsigninState(false);
  };

  const signinEnabler = () => {
    setsignupState(false);
    setsigninState(true);
  };

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const showSignup = query.get("showSignup") === "true";

    console.log(query)
    console.log('showSignup',showSignup)
    if (showSignup) {
      signupEnabler();

      // Update the URL without navigating
      const newQuery = new URLSearchParams(location.search);
      newQuery.delete("showSignup");
      const newUrl = `${location.pathname}?${newQuery.toString()}`;
      window.history.replaceState(null, "", newUrl);
    }
    else{
        signinEnabler()
    }
  }, [location.search]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    if (isLoggedIn) {
      const query = new URLSearchParams(location.search);
      const nextPath = query.get("next");
      const queryParams = new URLSearchParams({
        priceId : query.get("priceId"),
        paymentType : query.get("paymentType"),
        stripeCouponIds : query.get("stripeCouponIds"),
    }).toString();
      if (nextPath) {
        if(nextPath == '/pricing')
        {
            navigate(`/pricing?${queryParams}`);
        }
        else
        {
            navigate(nextPath);
        }
      } else {
        navigate("/projects");
      }
    }
  }, [isLoggedIn, navigate, location.search]);

  return (
    <>
      <Navbar />
      <Helmet>
        <title>Account | Cyber Visionaries</title>
      </Helmet>
      <div className="row m-0">
        <div className="col-12 p-0">
        {signinState && <Signin loginEnabler={signupEnabler} />}
        {signupState && <Signup signinEnabler={signinEnabler} />}
        </div>
        <div className="col-12 px-2 py-3 p-lg-4 mt-4" style={{backgroundColor:"rgba(0, 126, 178, 1)"}}>
        <Footer/>
        </div>
      </div>
    </>
  );
};

export default Account;
