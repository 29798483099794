import React, { useEffect, useState, useMemo,useCallback } from 'react';
import logoImage from '../assets/logo.png';
import styles from './styles/Navbar.module.css';
import { useNavigate } from 'react-router-dom';
import { Button, Drawer, notification, Space, Menu } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { removeAuthToken, setLoggedIn } from '../redux/AuthToken/Action';
import { setCookieConsent } from '../redux/CookieConsent/Action';
import cookieImage from '../assets/cookies.svg';
import { DOMAIN_NAME } from '../values/DomainName';
import FeedbackForms from './FeedbackForms';

const Context = React.createContext({ name: 'Default' });

const Navbar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [openOptions, setOpenOptions] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const { token, isLoggedIn } = useSelector((state) => state.authToken);
  const cookieConsentChecked = useSelector((state) => state.cookieConsent.cookieConsentChecked);
  const [api, contextHolder] = notification.useNotification();
  const cookieNotificationKey = useMemo(() => `cookie-notification-${Date.now()}`, []);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [Type_of_modal, setType_of_modal] = useState('');
  const [scrollPosition, setScrollPosition] = useState(0);
  const [scrollDirection, setScrollDirection] = useState('down');

  const showModal = (type) => () => {
    setType_of_modal(type);
    setIsModalOpen(true);
  };

  const contextValue = useMemo(() => ({ name: "User" }), []);

  const debounce = (func, wait) => {
    let timeout;
    return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => func(...args), wait);
    };
  };


  const handleScroll = useCallback(
    debounce(() => {
      const currentScrollPosition = window.pageYOffset;
      const direction = currentScrollPosition > scrollPosition ? "down" : "up";
      setScrollDirection(direction);
      console.log(direction)
      setScrollPosition(currentScrollPosition);
    }, 100),
    [scrollPosition]
  );

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    console.log(window.pageYOffset);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);
  const openNotification = () => {
    api.info({
      message: <p className='text-white'>Cookies Consent</p>,
      description: (
        <div className="text-center">
          <img src={cookieImage} alt="Cookies" height="150px" />
          <div className="text-start">
            <p className="text-white">
              Our website uses cookies to enhance your experience. Please allow
              cookies to use our website properly.
            </p>
            <Space>
              <Button type="primary" onClick={handleAllowCookies}>
                Allow
              </Button>
              <Button onClick={handleDeclineCookies}>Decline</Button>
            </Space>
          </div>
        </div>
      ),
      placement: "bottomLeft",
      duration: 0,
      key: cookieNotificationKey,
    });
  };

  useEffect(() => {
    const authenticateToken = async () => {
      try {
        await axios.get(`${DOMAIN_NAME}/auth/test_token/`, {
          headers: { Authorization: token },
        });
      } catch (error) {
        dispatch(setLoggedIn(false));
        dispatch(removeAuthToken());
        navigate("/");
      }
    };

    if (isLoggedIn && token) {
      authenticateToken();
    }
  }, [isLoggedIn, token, dispatch, navigate]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const toggleOptions = () => {
    setOpenOptions(!openOptions);
  };
  useEffect(() => {
    const checkCookiesEnabled = () => {
      const webAppCookie = document.cookie.indexOf("webAppCookie=true") !== -1;
      if (!webAppCookie && !cookieConsentChecked) {
        openNotification();
        dispatch(setCookieConsent(true));
      }
    };

    checkCookiesEnabled();
  }, [cookieConsentChecked, dispatch]);

  const handleAllowCookies = () => {
    document.cookie = "webAppCookie=true; max-age=" + 7 * 24 * 60 * 60 + "; path=/";
    api.destroy(cookieNotificationKey);
  };

  const handleDeclineCookies = () => {
    api.destroy(cookieNotificationKey);
  };

  const infoMenu = (
    <Menu>
      <Menu.Item key="1" onClick={() => navigate("/refundPolicy")}>Refund Policy</Menu.Item>
      <Menu.Item key="3" onClick={() => navigate("/privacyPolicy")}>Privacy Policy</Menu.Item>
      <Menu.Item key="2" onClick={() => navigate("/termsOfService")}>Terms of Service</Menu.Item>
    </Menu>
  );
  const handleNavigation = (path) => () => {
    if (path === '/#testimonials' || path ==="/#faq") {
      window.location.href = path; // For hash-based navigation within the page
    } else {
      navigate(path);
    }
  };
  return (
    <Context.Provider value={contextValue}>
      {contextHolder}
      <span>
  <nav className={`navbar navbar-expand-lg ${styles.navbar} ${scrollDirection == "up" && 'position-fixed'}`} style={{ 
    left: '50%', 
    transform: 'translateX(-50%)', 
    width: `${windowWidth > 992 ? "calc(100% - 48px)" : "100%"}`, // Adjust width to account for the desired margins
    margin: `${windowWidth > 992 ? "24px 0px" : "0px"}` // Adjust margins if necessary
  }}>
    {/* <div className="container"> */}
      <a className="navbar-brand" onClick={() => navigate('/')}>
        <img src={logoImage} alt="Logo" className={`${styles.navbarLogo}`} />
      </a>
      {windowWidth <= 992 && 
        <span className={`navbar-toggler d-flex align-items-center ${styles.navbarToggler}`} type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <Button type="primary" className={`${styles.tryTool} me-3`} onClick={() => navigate('/account?showSignup=true')}> GET STARTED </Button>
          <i className={`fa-solid fa-user ${styles.profileIcon}`} style={{ color: "white" }} onClick={() => navigate('/account?showSignup=false')}></i>
          <span className={`navbar-toggler-icon me-2 ${styles.navbarTogglerIcon}`} onClick={toggleOptions}></span>
        </span>
      }
      <div className="collapse navbar-collapse" id="navbarSupportedContent">
        {windowWidth > 992 && 
          <div className={`d-flex align-items-center ms-auto ${styles.navItems}`}>
            <p className={`nav-link mb-0 ${styles.btnStyles}`} onClick={() => navigate('/pricing')}>Pricing</p>
            <i className={`fa-solid fa-user ${styles.profileIcon}`} style={{ color: "white" }} onClick={() => navigate('/account?showSignup=false')}></i>
            <Button type="primary" className={`${styles.tryTool} me-4`} onClick={() => navigate('/account?showSignup=true')}> GET STARTED </Button>
          </div>
        }
      </div>
    {/* </div> */}
  </nav>
  <Drawer
    title="Menu"
    placement="right"
    onClose={toggleOptions}
    open={openOptions}
  >
    <Menu className={styles.navbar_toggled_options}>
      <ul>
        <li onClick={handleNavigation('/pricing')}>Pricing</li>
        <li onClick={handleNavigation('/#faq')}>FAQs</li>
        <li onClick={handleNavigation('/#testimonials')}>Testimonials</li>
        <li onClick={showModal("apply_affiliate")}>Affiliate Program</li>
        <li onClick={showModal("contact")}>Contact Us</li>
        <li onClick={handleNavigation('/termsOfService')}>Terms of Service</li>
        <li onClick={handleNavigation('/privacyPolicy')}>Privacy Policy</li>
        <li onClick={handleNavigation('/refundPolicy')}>Refund Policy</li>
        <li onClick={handleNavigation('/account')}>My Account</li>
        <li onClick={showModal("report")}>Report a Bug</li>
        <li onClick={showModal("feedback")}>Leave Feedback</li>
      </ul>
    </Menu>
  </Drawer>
      {scrollDirection == "up" && <div className={`row m-0 ${styles.hidden_element}`}>

</div>}
</span>


      {isModalOpen && <FeedbackForms isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} typeOfModal={Type_of_modal} />}
    </Context.Provider>
  );
};

export default Navbar;
