import axios from 'axios';
import { message } from 'antd';
import { DOMAIN_NAME } from './DomainName';

export const startTimer = (timer, setTimer, setShowTimer, setTimerRunning) => {
  setShowTimer(true);
  setTimerRunning(true);
  const interval = setInterval(() => {
    if (timer > 0) {
      setTimer((prevTimer) => prevTimer - 1);
    } else {
      setShowTimer(false);
      setTimerRunning(false);
      clearInterval(interval);
    }
  }, 1000);

  return () => clearInterval(interval);
};

export const sendVerificationEmail = async (email, setToken, setShowConfirmationCodeForm, enableTimer,forgotPassword) => {
  try {
    const response = await axios.post(`${DOMAIN_NAME}/auth/send_verification_email/`, {
      email: email,
      forgot_password:forgotPassword
    });

    setToken(response.data.code_token);
    if (response.status === 200) {
      setShowConfirmationCodeForm(true);
      message.success(`Verification code has been sent to ${email}`)
      enableTimer();
    }
    return response
  } catch (error) {
    console.error('Email sending error', error);
    return error.response
  }
};

export const verifyCode = async (code, token, setAuthToken, navigate, onSuccess, onError) => {
  try {
    const response = await axios.post(`${DOMAIN_NAME}/auth/authenticate_verification_code/`, {
      verification_code: code,
      code_token: token,
    });
    if (response.status === 200) {
      setAuthToken(token)
    //   setAuthToken(response.data.token);  isko change krna hai agr backend se token ata h to
      onSuccess(response);
    }
  } catch (error) {
    onError(error);
  }
};

export const passwordRules = [
  { required: true, message: "Enter password" },
//   { min: 8, message: "Password must be at least 8 characters" },
//   {
//     pattern: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@#$%^&+=!]*$/,
//     message: "Password must be alphanumeric ",
//   },
];

export const signUppasswordRules = [
    { required: true, message: "Enter password" },
    { min: 8, message: "Password must be at least 8 characters" },
    {
    //   pattern: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@#$%^&+=!]*$/,
    //   message: "Password must be alphanumeric ",
    },
  ];