import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { LogoutOutlined } from "@ant-design/icons";
import { Button, Spin, Switch, message, Popconfirm } from "antd";
import { setLoggedIn, removeAuthToken } from "../redux/AuthToken/Action";
import Navbar from "../components/Navbar";
import axios from "axios";
import Title from "../components/Title";
import "./styles/Settings.css";
import { Helmet } from "react-helmet";
import { DOMAIN_NAME } from "../values/DomainName";
import { API_GET_WEBVIDEO_SETTINGS, API_SET_WEBVIDEO_SETTINGS } from "../api/api_settings";

const Settings = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showSpinner, setShowSpinner] = useState(false);
  const [settingsChanged, setSettingsChanged] = useState(false);
  const token = useSelector((state) => state.authToken.token);
  const [settings, setSettings] = useState({
    notification_enabled: false,
  });

  const logoutUser = () => {
    dispatch(setLoggedIn(false));
    dispatch(removeAuthToken());
    navigate("/");
  };

  const handleEmailChange = (checked) => {
    setSettings((prevSettings) => ({
      ...prevSettings,
      notification_enabled: checked,
    }));
    setSettingsChanged(true);
  };

  const getSettings = async () => {
    const response = await API_GET_WEBVIDEO_SETTINGS(setShowSpinner,token)
    setSettings(response)
  };

  useEffect(() => {
    getSettings();
  }, []);

  const confirmSettings = async () => {
    setShowSpinner(true);
    const data = {
      notification_enabled: settings.notification_enabled,
    };
    await API_SET_WEBVIDEO_SETTINGS(setShowSpinner,token,data,setSettingsChanged)
  };

  return (
    <div>
      {showSpinner && <Spin fullscreen />}
      
      <Helmet>
        <title>Settings | Cyber Visionaries</title>
      </Helmet>
      {/* <Navbar /> */}
      <Title title={"Settings"} />
      <div className={`row m-0 justify-content-center ${'main'}`}>
        <div className={`col-11`}>
        <Switch size="small" checked={settings.notification_enabled} onChange={handleEmailChange}  />

          <span className={'emailCheckboxText'}>
            Send email when project is completed
          </span>
        </div>
        <div className={`col-11 my-3 ${'buttonContainer'}`}>
          <Button type="primary" onClick={confirmSettings} className={'confirmButton'} > Confirm Settings </Button>
        </div>
      </div>
    </div>
  );
};

export default Settings;
