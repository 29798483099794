import React, { useState, useEffect } from "react";
import {
  EditOutlined,
  DeleteOutlined,
  ArrowRightOutlined,
} from "@ant-design/icons";
import { Statistic, Modal, Form, Input, message, Spin, Popconfirm } from "antd";
import styles from "./styles/SingleProject.module.css";
import moment from "moment";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CountdownDisplay from "./CountDownDisplay";
import axios from "axios";
import CountDownDisplay from "./CountDownDisplay";
import { DOMAIN_NAME } from "../values/DomainName";
import deleteiconsinglecard from '../assets/deleteiconsinglecard.svg'
import { API_DELETE_PROJECT } from "../api/api_project";

const SingleProjectCard = ({ projectDetails, flipRerenderer }) => {
  const navigate = useNavigate();
  const [StatusColor, setStatusColor] = useState("#ff0000");
  const token = useSelector((state) => state.authToken.token);
  const { Countdown } = Statistic;
  const [ShowSpinner, setShowSpinner] = useState(false);

  const expirationDays = 14;

  const createdAt = moment(projectDetails?.created_at);
  const deadline = createdAt.add(expirationDays, "days").valueOf();
  console.log(projectDetails)

  useEffect(() => {
    switch (projectDetails?.project_state) {
      case "Completed":
        setStatusColor("rgba(16, 198, 0, 1)");
        break;
      case "Draft":
        setStatusColor("rgba(144, 144, 144, 1)");
        break;
      case "Error":
        setStatusColor("#ff0000");
        break;
      case "In Progress":
        setStatusColor("rgba(255, 152, 30, 1)");
        break;
      default:
        setStatusColor("#ffbf00");
        break;
    }

    if (moment().isAfter(deadline)) {
      deleteProject();
      document
        .getElementById(`project-${projectDetails.id}`)
        .classList.add("d-none");
    }
    console.log(projectDetails)
  }, [projectDetails?.project_state, projectDetails?.created_at]);


  const deleteProject = async () => {
    const response = await API_DELETE_PROJECT(setShowSpinner,projectDetails?.id,token)
    if(response){
        document .getElementById(`project-${projectDetails.id}`) .classList.add("d-none");
    flipRerenderer();
    }
   
  };

  const onFinish = () => {
  };

  const clickHandler =()=>{
     navigate(`/project/${projectDetails?.id}`, { state: { projectDetails } })
  }
  return (
    <>
  {ShowSpinner && <Spin fullscreen />}
  <div id={`project-${projectDetails.id}`} className={`col-12 px-2 col-xl-4  col-xxl-3  col-md-6 px-2 mt-4`}>
    <div className={`row justify-content-center m-0 mt-2 p-0 ${styles.innerContainer} ${styles.projectContainer}`} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}  >
      <div className="col-12 align-items-center">
        <div className={`row ${styles.blur_container} justify-content-center`}>
          <div className={`col-12 ${styles.blur_content}`} onClick={clickHandler}>
            <div>
              <p className="mb-0">
                <span style={{ color: StatusColor }} className={styles.statusText}>
                  <div className="mt-4" dangerouslySetInnerHTML={{  __html: projectDetails?.project_state === 'Completed' ? '&#10003; Ready for Download' : projectDetails?.project_state === 'Draft' ? '&ndash; Draft' : projectDetails?.project_state === 'In Progress' ? '<i class="fa-regular fa-circle"></i> In Progress': projectDetails?.project_state === 'Error' ? 'Error' : '', }} />
                </span>
              </p>
              <p className={styles.projectName} style={{ display: '-webkit-box', WebkitBoxOrient: 'vertical', WebkitLineClamp: 2, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'normal', }} >
                {projectDetails?.name.slice(0, 65)}{projectDetails?.name.length>65 && "..."}
              </p><p className={styles.last_edited}> Last edited: {projectDetails?.updated_at?.slice(0, 10).replaceAll('-', '/')} </p>

              <p className={styles.last_edited}>Leads: {projectDetails?.leads}</p>
            </div>
          </div>

          <div className="row m-0 text-center">
          <button className={`${styles.blur_button} text-center`} onClick={clickHandler}>
            Go to Campaign
          </button>
          </div>
        </div>
      </div>

      {/* Move the delete button to the bottom */}
      <div className="row m-0 mt-auto"> {/* Add mt-auto class */}
        <div className="col-12 p-0 mt-1">
          <div className={styles.icons}>
            <Popconfirm placement="topRight" description={"Are you sure you want to delete the project?"} title={"Delete Project"} okText="Yes" cancelText="No" onConfirm={deleteProject} okType="danger" className="pb-3" >
              <img src={deleteiconsinglecard} alt="" />
            </Popconfirm>
          </div>
        </div>
      </div>
    </div>
  </div>
</>

  );
};

export default SingleProjectCard;