import React from 'react'
import Navbar from '../components/Navbar'
import errorImage from '../assets/notFound.png'
import styles from './styles/NotFound.module.css'
const NotFound = () => {
    
  return (
    <div>
      <Navbar/>
      <div className="row m-0 justify-content-center" style={{height:"80vh"}}>
        <div className="col-10 text-center align-self-center" >
            <img src={errorImage} alt="" className={styles.image}/>
        </div>
      </div>
    </div>
  )
}

export default NotFound
