export const MENU = {
    itemSelectedBg:"rgb(42, 113, 155)",
  };

  export const NOTIFICATION = {
    colorBgElevated:"rgb(42, 113, 155)",
    colorText:"white !important",
    colorPrimary:"white",
    colorInfo:"white",

  };
