import React, { useState, useEffect } from "react";
import styles from "./styles/EditProject.module.css";
import {  ReloadOutlined, EditOutlined, CheckOutlined,ArrowUpOutlined, ArrowLeftOutlined,MobileOutlined,LaptopOutlined,} from "@ant-design/icons";
import { Button, Input, Select, Checkbox, Tabs,Slider,Switch,Segmented,Spin} from "antd";
import ImageEditor from "./ImageEditor";
import image1 from "../assets/asg template.png";
import {applyChangesHandler, calculateScreenShotValues, getVideoDimensions,handleResize, INITIAL_HEIGHT_AND_WIDTH, saveDraftHandler} from '../values/VideoCalculations'
import { DOMAIN_NAME } from "../values/DomainName";
import CustomQuantitySelector from "../components/CustomQuantitySelector";
import { API_SAVE_DRAFT } from "../api/api_project";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";


const EditProject = ({onConfirmChanges,projectName,onBackBtnListener,importedVideoPath,projectDetails}) => {
    const id = window.location.href.substring(window.location.href.lastIndexOf('/') + 1);
    const navigate = useNavigate()
  const [dragging, setDragging] = useState(false);
  const [offset, setOffset] = useState({ x: 20, y: 20 });
  const [position, setPosition] = useState({ x: Math.max(0,((projectDetails?.imported_video_pos_tl_x / projectDetails?.screenshot_width)*100)) , y: Math.max(0,((projectDetails?.imported_video_pos_tl_y / projectDetails?.screenshot_height)*100)) }); // Initial position as a percentage, aligned to bottom left
// console.log('position initial',{ x: Math.max(0,((projectDetails?.imported_video_pos_tl_x / projectDetails?.screenshot_width)*100)) , y: Math.max(0,((projectDetails?.imported_video_pos_tl_y / projectDetails?.screenshot_height)*100)) })
  //? math.max is used because if draft is never called then it gives -1 and as a result the output video position is not top left but it shows top left
  const [showSpinner, setShowSpinner] = useState(false);
  const token = useSelector((state) => state.authToken.token);
  const [outerSize, setOuterSize] = useState({ width: 0, height: 0 });
  const [borderWeight, setborderWeight] = useState((projectDetails?.border_weight)); // Default border width
  const [borderColor, setBorderColor] = useState(projectDetails?.border_color); // Default border color
  const [borderRadius, setBorderRadius] = useState((projectDetails?.border_radius)); // Default border radius
  const borderTransparency = typeof projectDetails?.border_transparency === 'number' 
  ? projectDetails.border_transparency 
  : 0;

// Format the number to 2 decimal places
const [alphaValue, setAlphaValue] = useState(borderTransparency.toFixed(2));
  const [height, setHeight] = useState(projectDetails?.screenshot_height); // Default height
  const [width, setWidth] = useState(projectDetails?.screenshot_width); // Default width
  const [scrollingGap, setscrollingGap] = useState(projectDetails?.scroll_gap);

  const [muteVideo, setmuteVideo] = useState(projectDetails?.is_mute);
  const [initialDelay, setInitialDelay] = useState(projectDetails?.initial_delay); // Default to 0
  const [startScrolling, setStartScrolling] = useState(projectDetails?.scroll_from_top ? 'top' : 'bottom'); // Default to "top"
  const [randomScrolling, setRandomScrolling] = useState(projectDetails?.random_scrolling);
  const [DisableScroll, setDisableScroll] = useState(projectDetails?.disable_scroll);
    const [windowWidth, setwindowWidth] = useState(window.innerWidth);
//   const [screenShotHeightValue, setscreenShotHeightValue] = useState("35vw");
//   const [screenShotWidthValue, setscreenShotWidthValue] = useState("52.5vw");


  
  const [screenShotHeightValue, setscreenShotHeightValue] = useState(`${INITIAL_HEIGHT_AND_WIDTH(projectDetails?.screenshot_height,projectDetails?.screenshot_width).screenshot_height}vw`);
  const [screenShotWidthValue, setscreenShotWidthValue] = useState(`${INITIAL_HEIGHT_AND_WIDTH(projectDetails?.screenshot_height,projectDetails?.screenshot_width).screenshot_width}vw`);

  const [cropped_image_bottom_left, setcropped_image_bottom_left] = useState({});
  const [cropped_image_top_left, setcropped_image_top_left] = useState({});
  const [cropped_image_top_right, setcropped_image_top_right] = useState({});
  const [cropped_image_bottom_right, setcropped_image_bottom_right] = useState({});
  const [showEditor, setshowEditor] = useState(false);
  const [image_cropped, setimage_cropped] = useState(""); // Set default video
  const [originalImage, setoriginalImage] = useState(""); // Set defaut
  const [showCroppedVideo, setShowCroppedVideo] = useState(false);
  const [croppedCoordinates, setCroppedCoordinates] = useState(null);
  const [imported_video_original_dimensions,setimported_video_original_dimensions,] = useState({ width: 0, height: 0 });
  const [isVideoPresent, setisVideoPresent] = useState(false);
  const [currentTab, setcurrentTab] = useState(1);
  const { Option } = Select;

useEffect(()=>{
    // console.log(startScrolling)
},[startScrolling])



  useEffect(() => {
setShowSpinner(true)
    setimage_cropped(`${DOMAIN_NAME}${importedVideoPath}`);
    setoriginalImage(`${DOMAIN_NAME}${importedVideoPath}`);
    if (importedVideoPath) {
      setisVideoPresent(true);
    }
    const outerDiv = document.getElementById("outer-div");
    const rect = outerDiv.getBoundingClientRect();
    setOuterSize({ width: rect.width, height: rect.height });
    // handleResize(position, setOuterSize, setPosition) ------------------------ 1
    const setDim = async () => {
      if (importedVideoPath) {
        setimported_video_original_dimensions(
          await getVideoDimensions(`${DOMAIN_NAME}${importedVideoPath}`)
        );
      }
    };
    setShowSpinner(false)
    setDim();
  }, []); // Trigger only once to get the outer div's size


  useEffect(() => {
    
    window.addEventListener("resize", () => {
        setwindowWidth(window.innerWidth)
        handleResize(position, setOuterSize, setPosition)});
    return () => {
        window.removeEventListener("resize", () => handleResize(position, setOuterSize, setPosition));
    };
}, [height, width]);

useEffect(() => {
    handleConfirm()
    // handleResize(position, setOuterSize, setPosition);
    const outerDiv = document.getElementById("outer-div");
    const rect = outerDiv.getBoundingClientRect();
    setOuterSize({ width: rect.width, height: rect.height });
    console.log('rect',projectDetails?.imported_video_pos_tl_x )
    setPosition({
        x: Math.max(0, (isNaN(projectDetails?.imported_video_pos_tl_x) ? 0 : (projectDetails?.imported_video_pos_tl_x / projectDetails?.screenshot_width) * 100)),
        y: Math.max(0, (isNaN(projectDetails?.imported_video_pos_tl_y) ? 0 : (projectDetails?.imported_video_pos_tl_y / projectDetails?.screenshot_height) * 100))
      });
      
}, [screenShotHeightValue, screenShotWidthValue]);

  const handleMouseDown = (e) => {
    setDragging(true);
    const newX =((e.clientX - (position.x * outerSize.width) / 100) * 100) /outerSize.width;
    const newY =((e.clientY - (position.y * outerSize.height) / 100) * 100) /outerSize.height;
    // console.log("OFFSET",position)
    setOffset({ x: newX, y: newY });
  };

  const handleMouseMove = (e) => {
    if (dragging) {
      // Calculate the new position of the inner div
      let newX = ((e.clientX - (offset.x * outerSize.width) / 100) * 100) / outerSize.width;
      let newY = ((e.clientY - (offset.y * outerSize.height) / 100) * 100) / outerSize.height;
  
    //   console.log('newx new',newX,newY)
      // Dynamically calculate the width and height of the inner div based on outer div dimensions
      const innerDivWidth = Math.min(outerSize.height,outerSize.width)*0.3;
      const innerDivHeight = Math.min(outerSize.height,outerSize.width)*0.3;
  
      // Ensure the inner div stays within the boundaries of the outer div
      newX = Math.max(0, Math.min(newX, 100 - (innerDivWidth * 100) / outerSize.width)); // Adjust for the width of inner div
      newY = Math.max(0, Math.min(newY, 100 - (innerDivHeight * 100) / outerSize.height)); // Adjust for the height of inner div
  
      setPosition({ x: newX, y: newY });
    }
  };
  
  const handleTouchStart = (e) => {
    console.log('1')
    window.addEventListener("mousemove", preventDefault);
    window.addEventListener("touchmove", preventDefault);
    setDragging(true);
    const touch = e.touches[0];
    const newX = ((touch.clientX - (position.x * outerSize.width) / 100) * 100) / outerSize.width;
    const newY = ((touch.clientY - (position.y * outerSize.height) / 100) * 100) / outerSize.height;
    
    setOffset({ x: newX, y: newY });
  };

  const handleTouchMove = (e) => {
    console.log("2")
    
    if (dragging) {
      const touch = e.touches[0];
      let newX = ((touch.clientX - (offset.x * outerSize.width) / 100) * 100) / outerSize.width;
      let newY = ((touch.clientY - (offset.y * outerSize.height) / 100) * 100) / outerSize.height;
      const innerDivWidth = Math.min(outerSize.height,outerSize.width)*0.3;
      const innerDivHeight = Math.min(outerSize.height,outerSize.width)*0.3;
      newX = Math.max(0, Math.min(newX, 100 - (innerDivWidth * 100) / outerSize.width));
      newY = Math.max(0, Math.min(newY, 100 - (innerDivHeight * 100) / outerSize.height));
    //   console.log('newx new',newX,newY)
      setPosition({ x: newX, y: newY });
    }
  };

  const handleTouchEnd = () => {
    window.removeEventListener("mousemove", preventDefault);
    window.removeEventListener("touchmove", preventDefault);
    setDragging(false);
  };

  const preventDefault = (e) => {
    e.preventDefault();
  };
  const handleConfirm = (explicitHeight, explicitWidth, isExplicit) => {
    const innerDiv = document.getElementById("inner-div");
      handleResize("topLeft", setOuterSize, setPosition);
    if (innerDiv) {
      innerDiv.style.border = `${borderWeight}px solid ${getBorderColor()}`;
      innerDiv.style.borderRadius = `${Math.min(borderRadius, 50)}%`;
    }
    if (isExplicit) {
      const { sswidth, ssheight } = calculateScreenShotValues(
        explicitWidth,
        explicitHeight
      );
      setscreenShotHeightValue(ssheight);
      setscreenShotWidthValue(sswidth);
    } else {

      const { sswidth, ssheight } = calculateScreenShotValues(width, height);
      setscreenShotHeightValue(ssheight);
      setscreenShotWidthValue(sswidth);
    }
  
  };
  
  const handleMobileClick = () => {
    setHeight(800);
    setWidth(400);
    handleConfirm(800, 400, true); // Update screenshot size instantly
  };
  
  const handleLaptopClick = () => {
    setHeight(600);
    setWidth(1600);
    handleConfirm(600, 1600, true); // Update screenshot size instantly
  };
// useEffect(()=>{
//     console.log(position)
// },[position])
  const getBorderColor = () => {
    // Convert alpha value from 0 to 1 to range from 0 to 255
    const alpha = Math.round(alphaValue * 255);
    return `${borderColor}${alpha.toString(16).toUpperCase()}`; // Append alpha value to border color
  };

  const onCropComplete = async (res) => {
    // Resolve the promise to get the result
    const croppedArea = await res;
    // Now you can access the coordinates of the cropped area from the result
    const { x, y, width, height } = croppedArea.coordinates;
    // Update the state or perform any other necessary actions with the coordinates
    setcropped_image_bottom_left({ x: x, y: y + height });
    setcropped_image_bottom_right({ x: x + width, y: y + height });
    setcropped_image_top_left({ x: x, y: y });
    setcropped_image_top_right({ x: x + width, y: y });
    setCroppedCoordinates({ x, y, width, height });
    setShowCroppedVideo(true);
    // Set showEditor to false to hide the ImageCropper component
    setshowEditor(false);
  };

  const CustomTab = ({ title }) => <div className={`${styles.tab} mb-1`}>{title}</div>;

  return (
    <>{showSpinner && <Spin fullscreen />}
      {showEditor ? (
        <ImageEditor media={originalImage} onCropComplete={onCropComplete} />
      ) : (
        <>
          <div className="row m-0 px-0 pt-4 pb-5">
            <div className="col-12 align-self-center d-flex justify-content-between">
            <span className="d-flex">
                {/* {isVideoPresent && ( 
                <Button type="primary" className={styles.editBtn} icon={<EditOutlined className={styles.icon} />} onClick={() => setshowEditor(true)} > {" "} <span className={styles.btnText}>Edit Video</span>{" "} </Button> )}
                 */}
                {/* <Button type="primary" className={styles.btn_outlined} icon={<i class="fa-solid fa-arrow-left"/>} onClick={() => onBackBtnListener()} > Discard changes</Button> */}
                
              </span>
            </div>

            <div className="col-12 align-self-center text-center p-0 ">
              <div className="row m-0 justify-content-around text-center align-item pe-xxl-3">
                <div className="col-10 col-xxl-9 text-center" >
                  <div
                    id="outer-div"
                    className="row m-0 mt-4"
                    onMouseMove={handleMouseMove}
                    onMouseUp={()=>setDragging(false)}
                    onMouseLeave={()=>setDragging(false)}
                    onTouchStart={handleTouchStart}
                    onTouchMove={handleTouchMove}
                    onTouchEnd={handleTouchEnd}
                    style={{
                      height: screenShotHeightValue,
                      width: screenShotWidthValue,
                      position: "relative",
                      border: "0.5px solid black",
                      backgroundColor: "rgba(242, 247, 250, 1)",
                    }}
                  >
                    {isVideoPresent && (
                      <div
                        id="inner-div"
                        style={{
                          width: `${ Math.min(outerSize.height,outerSize.width)*0.3 }px`, // Width as 30% of outer div's height
                          height: `${ Math.min(outerSize.height,outerSize.width)*0.3 }px`, // Height as 30% of outer div's height
                          position: "relative",
                          left: `${position.x}%`,
                          top: `${position.y}%`,
                          cursor: "grab",
                          borderRadius: `${Math.min(borderRadius, 50)}%`, // Apply border radius to inner div
                          overflow: "hidden", // Hide overflowing content
                          border: `${borderWeight}px solid ${getBorderColor()}`, // Add border to inner div
                          boxSizing: "border-box", // Include border width in the size calculation
                          padding: "0px",
                        }}
                        onMouseDown={handleMouseDown}
                      >
                        {showCroppedVideo && croppedCoordinates ? (
                          <video src={image_cropped} autoPlay loop  muted={muteVideo}
                            style={{
                              position: "absolute",
                              left: `-${ (croppedCoordinates.x * ((imported_video_original_dimensions.width * Math.min(outerSize.height,outerSize.width)*0.3) /  croppedCoordinates.width)) / imported_video_original_dimensions.width}px`,
                              top: `-${ (croppedCoordinates.y * ((imported_video_original_dimensions.height * Math.min(outerSize.height,outerSize.width)*0.3) / croppedCoordinates.height)) / imported_video_original_dimensions.height}px`,
                              width: `${ (imported_video_original_dimensions.width * Math.min(outerSize.height,outerSize.width)*0.3) / croppedCoordinates.width}px`,
                              height: `${ (imported_video_original_dimensions.height * Math.min(outerSize.height,outerSize.width)*0.3) / croppedCoordinates.height }px`,
                              objectFit: "fill",
                            }}
                          />
                        ) : (
                          <video src={image_cropped} alt="Video" muted={muteVideo} autoPlay loop style={{ height: "100%", width: "100%", objectFit: "cover", }}/>
                        )}
                      </div>
                    )}
                  </div>
                </div>
                <div className={`col-10 col-xxl-3 p-0 text-start `}>
                    <div className={`row m-0 ${styles.menu}`}>
                        <div className={`col-12 p-3  ${styles.menu_inner}`}>

                        <Tabs defaultActiveKey="1">
                    <Tabs.TabPane tab={ <CustomTab title={"Video"} onClick={() => setcurrentTab(1)} active={currentTab === 1} /> } key="1" >
                      <div className="row justify-content-center m-0">
                      {/* <VIDEO_TABS_PANE handleMobileClick={handleMobileClick} handleLaptopClick={handleLaptopClick} handleResize={handleResize} setOuterSize={setOuterSize} setPosition={setPosition} setHeight={setHeight} setWidth={setWidth} handleConfirm={handleConfirm} setStartScrolling={setStartScrolling} setRandomScrolling={setRandomScrolling} setDisableScroll={setDisableScroll} setmuteVideo={setmuteVideo} setscrollingGap={setscrollingGap} setInitialDelay={setInitialDelay} height={height} width={width} startScrolling={startScrolling} option={Option} randomScrolling={randomScrolling} DisableScroll={DisableScroll} muteVideo={muteVideo} scrollingGap={scrollingGap} initialDelay={initialDelay}/> */}

                        <div className="col-12 p-0">
                            <p className={styles.inputTitles}>Video Orientation</p>    
                        </div>
                        <div className={`col-12 p-0 ${styles.inputFields} ${styles.fixedDimensions} text-center`} style={{ backgroundColor: 'transparent' }}>
                        
                            <p className={`${styles.inputTitles} p-0`}>
                                <div className="row m-0">
                                    <div className="col-6 p-0" onClick={handleMobileClick}><MobileOutlined key="mobile"  className={styles.mobileButton} /></div>
                                    <div className="col-6 p-0"onClick={handleLaptopClick}><LaptopOutlined key="laptop"  className={styles.laptopButton} /></div>
                                </div>
                            </p>
                        </div>
                        <div className="row pt-3 justify-content-center pb-3 m-0 p-0">
                            <div className="col-12 p-0">
                                <p className={styles.inputTitles}>Uploaded video (camera) position</p>  
                                <Button type="primary" onClick={()=>{handleResize("topLeft",setOuterSize, setPosition)}} className={`${styles.button} ${styles.topLeft}`} icon={<ArrowUpOutlined style={{ transform: "rotate(-45deg)" }} />} />
                                <Button type="primary" onClick={()=>handleResize("topRight",setOuterSize, setPosition)} className={`${styles.button} ${styles.topRight}`} icon={<ArrowUpOutlined  style={{ transform: "rotate(45deg)" }}/>} />
                            </div>
                            <div className="col-12 p-0">
                                <Button type="primary" onClick={()=>handleResize("bottomLeft",setOuterSize, setPosition)} className={`${styles.button} ${styles.bottomLeft}`} icon={<ArrowUpOutlined style={{ transform: "rotate(-135deg)" }}/>} />
                                <Button type="primary" onClick={()=>handleResize("bottomRight",setOuterSize, setPosition)} className={`${styles.button} ${styles.bottomRight}`} icon={<ArrowUpOutlined style={{ transform: "rotate(135deg)" }}/>} />
                            </div>
                        </div>
                        
                        <div className={`col-6 p-0 pe-1 ${styles.inputFields}`}>
                          <p className={styles.inputTitles}>{`Video Height (px)`}</p>
                          <Input type="number" placeholder="Height" min="500" max="1920" value={height} onChange={(e) => { setHeight(e.target.value) }} onBlur={()=>{height<500&&setHeight(500)||height>1920&&setHeight(1920)}} size="small"  />
                        </div>
                        <div className={`col-6 p-0 ps-1 ${styles.inputFields}`}>
                          <p className={styles.inputTitles}>{`Video Width (px)`}</p>
                          <Input type="number" placeholder="Width" min="300" max="1920" value={width} onChange={(e) => { setWidth(e.target.value);  }} onBlur={()=>{width<300&&setWidth(300)||width>1920&&setWidth(1920)}} size="small" />
                        </div>
                        <div className={`col-12 p-0 m-0 text-center ${styles.inputFields}`}>
                          <Button onClick={handleConfirm} icon={<ReloadOutlined />} className={`${styles.btn_outlined} m-0`} > Preview Dimensions </Button>
                        </div>
                        <div className={`col-12 p-0 pt-2 ${styles.inputFields}`}>
                            <p className={styles.inputTitles}>Start video from website’s...</p>
                            <Select value={startScrolling || "top"} onChange={(value) => setStartScrolling(value)} style={{ width: "100%" }} >
                                <Option value="top">Top</Option>
                                <Option value="bottom">Bottom</Option>
                            </Select>
                        </div>
                        
                        <div className={`col-12 p-0 ${styles.inputFields}`}>
                          <Checkbox checked={randomScrolling} onChange={(e) => setRandomScrolling(e.target.checked) } className={styles.inputTitles}>Random scrolling pattern</Checkbox>
                        </div>
                        <div className={`col-12 p-0 ${styles.inputFields}`}>
                          <Checkbox checked={DisableScroll} onChange={(e) => setDisableScroll(e.target.checked) } className={styles.inputTitles}>Disable scroll (show top of website)</Checkbox>
                        </div>
                        <div className={`col-12 p-0 ${styles.inputFields}`}>
                            <Checkbox checked={muteVideo} onChange={(e) => setmuteVideo(e.target.checked) } className={styles.inputTitles}>Mute Video</Checkbox>
                        </div>
                        <div className={`col-12 p-0 ${styles.inputFields}`}>
                          <p className={styles.inputTitles}>{`Scrolling gap (px)`}</p>
                          <Input type="number" placeholder="Scrolling gap" value={scrollingGap} onChange={(e) => { setscrollingGap(e.target.value); handleConfirm(); }} size="small"  />
                        </div>
                        {!randomScrolling && (
                          <div className="col-12 p-0">
                            <p className={styles.inputTitles}>Initial Delay before scrolling (sec)</p>
                            <Input type="number" placeholder="Initial Delay" value={initialDelay} onChange={(e) =>setInitialDelay(Math.min(e.target.value, 10))}className={styles.input}size="small"/>
                          </div>
                        )}
                        
                      </div>
                    </Tabs.TabPane>
                    {isVideoPresent && (
                      <Tabs.TabPane tab={ <CustomTab title={"Border"} onClick={() => setcurrentTab(2)} active={currentTab === 2} /> } key="2" >
                        <div className="row justify-content-center m-0">
                          <div className={`col-12  p-0${styles.inputFields}`}>
                            <CustomQuantitySelector text={"Weight"} value = {borderWeight} min={0} max={Math.floor((Math.min(outerSize.height, outerSize.width)*0.3)/4)} onChange={(value) => { setborderWeight(value);}}/>
                            <CustomQuantitySelector text={"Transparency"} value={alphaValue} min={0} max={1} step={0.01} onChange={(value) => { setAlphaValue(value);  }} />        
                            <CustomQuantitySelector text={"Roundness"} value={borderRadius} min={0} max={50} onChange={(value) => { setBorderRadius(Math.min(value, 50));  }} />        
                          </div>
                          <div className={`col-12 p-0 ${styles.inputFields}`}>
                            <p className={styles.inputTitles}>Color</p>
                            <Input type="color" placeholder="Border Color" style={{ width: "50px", marginBottom: "20px" }} value={borderColor} onChange={(e) => {setBorderColor(e.target.value); }} className={styles.input} size="small"/>
                          </div>
                        </div>
                      </Tabs.TabPane>
                    )}
                  </Tabs>
                        <div className="row m-0 mb-2 ">
                    <div className="col-12 col-sm-4 p-0 d-none d-sm-block">
                        <Button type="primary" className={styles.btn_outlined} onClick={async ()=>{ await saveDraftHandler({ token,width, height, outerSize, position, borderWeight, borderColor, alphaValue, borderRadius, cropped_image_bottom_left, cropped_image_bottom_right, cropped_image_top_left, cropped_image_top_right, initialDelay, startScrolling, randomScrolling,scrollingGap,muteVideo,DisableScroll}); onBackBtnListener() }}> Save Draft </Button>
                   
                    </div>
                    <div className="col-12 col-sm-8 p-0 ps-sm-2">
                        <Button className={styles.btn_filled}  onClick={()=>applyChangesHandler({ width, height, outerSize, position, borderWeight, borderColor, alphaValue, borderRadius, cropped_image_bottom_left, cropped_image_bottom_right, cropped_image_top_left, cropped_image_top_right, initialDelay, startScrolling, randomScrolling, onConfirmChanges,scrollingGap,muteVideo,DisableScroll})  } > {" "} <span className={styles.btnText}>Generate</span>{" "} </Button>
                   
                    </div>
                    <div className="col-12 col-sm-4 p-0 d-block d-sm-none">
                        <Button type="primary" className={styles.btn_outlined} onClick={async ()=>{ await saveDraftHandler({ token,width, height, outerSize, position, borderWeight, borderColor, alphaValue, borderRadius, cropped_image_bottom_left, cropped_image_bottom_right, cropped_image_top_left, cropped_image_top_right, initialDelay, startScrolling, randomScrolling,scrollingGap,muteVideo,DisableScroll}); onBackBtnListener() }}> Save Draft </Button>
                   
                    </div>
                  </div>
                  </div>
                    </div>
                  
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default EditProject;