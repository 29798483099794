import { useEffect, useState } from "react";
import "./App.css";
import ProjectRoutes from "./values/ProjectRoutes";
import { useSelector, useDispatch } from "react-redux"; // Import useDispatch
import { setReferrerUrl } from "./redux/AuthToken/Action";
import { DOMAIN_NAME } from "./values/DomainName";
// Import the action to set referrer URL

function App() {
  const [referrer, setReferrer] = useState("");
  const { token, isLoggedIn } = useSelector((state) => state.authToken);
  const dispatch = useDispatch(); // Initialize dispatch

  useEffect(() => {
    const referrerUrl = document.referrer;
    if(!referrerUrl.includes(DOMAIN_NAME))
        setReferrer(referrerUrl);
    if (referrerUrl) {
      dispatch(setReferrerUrl(referrerUrl));
    }
  }, [dispatch]); // Empty dependency array ensures this runs once on component mount

  return (
    <div className="App">
      <ProjectRoutes />
      {/* Include other components or elements as needed */}
    </div>
  );
}

export default App;
