import axios from "axios";
import { DOMAIN_NAME } from "../values/DomainName";
import { message } from "antd";
import { gapi } from "gapi-script"; // Import Google API script
import { CONVERT_SCROLLERVIDEOS_TO_URL_AND_NAMES, createFolder } from "../values/Helpers";
import JSZip from 'jszip'; // Import JSZip library

export const API_HANDLE_DOWNLOAD_TO_DRIVE = async (
    setShowSpinner,
    scrollerVideos,
    projectDetails,
    SCOPES,
    client_id,
    redirect_uri,
    setDOWNLOAD_TO_DRIVE_STARTED,
    setDriveLink
  ) => {
    // setDOWNLOAD_TO_DRIVE_STARTED(true);
    try {
      const { outputVideoPaths, customNames } =
        CONVERT_SCROLLERVIDEOS_TO_URL_AND_NAMES(scrollerVideos, projectDetails);
      await gapi.load("client:auth2", async () => {
        await gapi.client.init({
          clientId: client_id,
          scope: SCOPES,
          redirectUri: redirect_uri,
        });
  
        const GoogleAuth = gapi.auth2.getAuthInstance();
        await GoogleAuth.signIn();
  
        const accessToken = GoogleAuth.currentUser
          .get()
          .getAuthResponse().access_token;
  
        try {
          const folderId = await createFolder(
            `${projectDetails.name}`,
            accessToken
          );
  
          for (let i = 0; i < outputVideoPaths.length; i++) {
            const videoPath = outputVideoPaths[i];
            const response = await fetch(videoPath);
            const blob = await response.blob();
            const customName = customNames[i];
            const metadata = {
              name: customName,
              mimeType: "video/*",
              parents: [folderId],
            };
            const form = new FormData();
            form.append(
              "metadata",
              new Blob([JSON.stringify(metadata)], { type: "application/json" })
            );
            form.append("file", blob);
  
            setDOWNLOAD_TO_DRIVE_STARTED(true); //! new line
            const uploadResponse = await fetch(
              "https://www.googleapis.com/upload/drive/v3/files?uploadType=multipart",
              {
                method: "POST",
                headers: new Headers({
                  Authorization: "Bearer " + accessToken,
                }),
                body: form,
              }
            );
  
            if (uploadResponse.ok) {
              console.log(`Uploaded ${customName} to Google Drive.`);
            } else {
              throw new Error(`Failed to upload ${customName}`);
            }
          }
  
          // Construct the folder link
          const folderLink = `https://drive.google.com/drive/folders/${folderId}`;
          console.log("Folder uploaded: ", folderLink);
  
          message.success("Videos uploaded to Google Drive successfully!");
          setDriveLink(folderLink)
        } catch (error) {
          console.error(error);
          message.error("An error occurred while uploading videos.");
        } finally {
          setShowSpinner(false);
          setDOWNLOAD_TO_DRIVE_STARTED(false);
        }
      });
    } catch (error) {
      let errorMessage = "An unknown error occurred";
      if (error.response && error.response.data && error.response.data.message) {
        errorMessage = error.response.data.message;
      }
      message.error(errorMessage);
    }
  };
  


  export const API_HANDLE_DOWNLOAD_TO_PC = async(scrollerVideos, fileName,created_at,setShowSpinner)=>{
    setShowSpinner(true)
    let outputVideoPaths = [];
    let outputVideoNames = [];
    scrollerVideos.forEach(video => {
      outputVideoPaths.push(`${DOMAIN_NAME}${video.output_video}`);
      outputVideoPaths.push(`${DOMAIN_NAME}${video.output_gif}`);
      outputVideoNames.push(`${fileName}-${created_at}-${video.web_url}.mp4`);
      outputVideoNames.push(`${fileName}-${created_at}-${video.web_url}.gif`);
    });
    downloadVideosAsZip(outputVideoPaths, outputVideoNames,fileName, setShowSpinner );

  }
  function downloadVideosAsZip(videoPaths,outputVideoNames, fileName, setShowSpinner) {
    const zipVideos = new JSZip();
    let processedCount = 0;
    let currentVideoIndex = 0;
    
    const fetchPromises = videoPaths.map((videoPath, index) => {
      return fetch(videoPath)
        .then(response => response.blob())
        .then(videoBlob => {
          
          const videoName = outputVideoNames[index];
          zipVideos.file(videoName, videoBlob);
  
        })
        .catch(error => console.error('Error downloading video:', error))
        .finally(() => {
          processedCount++;
          if (processedCount === videoPaths.length) {
            generateZip(zipVideos, fileName, setShowSpinner);
          }
        });
    });
  
  
    function generateZip(zip, name, setShowSpinner) {
      zip.generateAsync({ type: 'blob' })
        .then(content => {
          const a = document.createElement('a');
          document.body.appendChild(a);
          a.style.display = 'none';
          const url = window.URL.createObjectURL(content);
          a.href = url;
          a.download = `${name}.zip`;
          a.click();
          window.URL.revokeObjectURL(url);
          document.body.removeChild(a);
          setShowSpinner(false)
        })
        .catch(error => console.error('Error generating zip folder:', error));
    }
  
    return Promise.all(fetchPromises);
  }