import React, { useEffect, useState } from "react";
import {  useNavigate } from "react-router-dom";
import { Button, message, Spin, List, Form, Tag } from "antd";
import { CompressOutlined, ArrowLeftOutlined, EditOutlined } from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import ProjectLinkCard from "../components/ProjectLinkCard";
import styles from "./styles/SingleProjectPage.module.css";
import EditProject from "./EditProject";
import { CLIENT_ID, REDIRECT_URI, SCOPES } from "../values/DomainName";
import {  API_DELETE_PROJECT, API_DELETE_SCROLLER_VIDEO, API_FETCH_PROJECT_ATTRIBUTES, API_GET_PROJECT_VIDEOS, API_RENAME_PROJECT, API_START_PROJECT } from "../api/api_project";
import { API_HANDLE_DOWNLOAD_TO_DRIVE, API_HANDLE_DOWNLOAD_TO_PC } from "../api/api_download";
import RenameProjectButton from "../components/RenameProjectButton";
import editiconeditproject from '../assets/editiconeditproject.svg'
import downloadicon from '../assets/downloadicon.svg'
import { Helmet } from "react-helmet";

import deleteiconeditproject from '../assets/deleteiconeditproject.svg'
import downloadicongrey from '../assets/downloadicongrey.svg'
const SingleProjectPage = () => {
  const navigate = useNavigate();
  const token = useSelector((state) => state.authToken.token);
  const [scrollerVideos, setScrollerVideos] = useState([]);
  const [DriveLink, setDriveLink] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [ImportedVideoPath, setImportedVideoPath] = useState("");
  const [isStarted, setIsStarted] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [isIntervalAlreadySet, setIsIntervalAlreadySet] = useState(false);
  const [projectDetails, setprojectDetails] = useState({});
  const [ProjectTitle, setProjectTitle] = useState(`${projectDetails?.name} | Cyber Visionaries`);
  const [DOWNLOAD_TO_PC_STARTED, setDOWNLOAD_TO_PC_STARTED] = useState(false);
  const [DOWNLOAD_TO_DRIVE_STARTED, setDOWNLOAD_TO_DRIVE_STARTED] =useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();


  const [allDetails, setAllDetails] = useState({
    screenshot_height: 800,
    screenshot_width: 1200,
    border_weight: 0,
    border_color: "#000000",
    border_transparency: 1,
    border_radius: 0,
    initial_delay: 0,
    scroll_starting: "top",
    random_scrolling: false,
    scroll_gap: 0,
    imported_video_pos_tl: { x: NaN, y: NaN },
    imported_video_pos_bl: { x: NaN, y: NaN },
    imported_video_pos_br: { x: NaN, y: NaN },
    imported_video_pos_tr: { x: NaN, y: NaN },
    imported_video_bl: { x: NaN, y: NaN },
    imported_video_br: { x: NaN, y: NaN },
    imported_video_tl: { x: NaN, y: NaN },
    imported_video_tr: { x: NaN, y: NaN },
  });

  let intervalId;

  const fetchProjects = async (id) => {
    await API_GET_PROJECT_VIDEOS(token,id,setShowSpinner,setprojectDetails,setScrollerVideos,setImportedVideoPath,setIsEditing,setProjectTitle) 
    await API_FETCH_PROJECT_ATTRIBUTES(id,token,setAllDetails)

  };


  useEffect(() => {
    const project_id_from_url = window.location.href.match(/project\/(\d+)/)[1];
    fetchProjects(project_id_from_url);
    
  }, [projectDetails.project_state]);


  const onConfirmChanges = (details) => {
    if (allDetails.initial_delay === "" || allDetails.scroll_starting === "") {
      message.error("Please fill out all fields.");
      return;
    }
    setIsEditing(false);
    const project_id_from_url = window.location.href.match(/project\/(\d+)/)[1];
    navigate(`/project/${project_id_from_url}`) //because we have a logic that if url contains new then setisediting to true 
    setAllDetails(details);
    setIsStarted(true);
    const data = { ...details, project_id: projectDetails.id, };
    API_START_PROJECT(token,data,navigate)
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const onBackBtnListener = () => {
    setIsEditing(false)
    const project_id_from_url = window.location.href.match(/project\/(\d+)/)[1];
    navigate(`/project/${project_id_from_url}`)
    fetchProjects(project_id_from_url)
  };

  const onDelete = async (linkId) => {
    await API_DELETE_SCROLLER_VIDEO(setShowSpinner,token,linkId,setScrollerVideos)
  };

  const handleDeleteClick = async()=>{
    const project_id_from_url = window.location.href.match(/project\/(\d+)/)[1];
    const response = API_DELETE_PROJECT(setShowSpinner,project_id_from_url,token)
    if(response){navigate('/projects')}
  }
  const handleModalVisible = (visible) => {
    setModalVisible(visible);
  };
  const handleDownloadToPC = async () => {
    setDOWNLOAD_TO_PC_STARTED(true);
    handleModalVisible(true);
    API_HANDLE_DOWNLOAD_TO_PC( scrollerVideos, projectDetails.name, projectDetails.created_at.slice(0, 10),setShowSpinner);
    setDOWNLOAD_TO_PC_STARTED(false);
  };
 
  
  const handleDownloadToDrive = async () => {
     await API_HANDLE_DOWNLOAD_TO_DRIVE(setShowSpinner,scrollerVideos,projectDetails,SCOPES,CLIENT_ID,REDIRECT_URI,setDOWNLOAD_TO_DRIVE_STARTED,setDriveLink)
    
  };
  const handleEditProjectNameClick = async () => {
    form.setFieldsValue({ name: projectDetails.name });
    setIsModalVisible(true);
  };

  return (
    <>
      {/* <Navbar /> */}
      {/* //! if it is turned on then the screen blinks */}
      {/* {showSpinner && <Spin fullscreen />} */} 
      <Helmet>
        <title>{ProjectTitle}</title>
      </Helmet>
      {isEditing ? (
        <EditProject projectId={projectDetails.id} onConfirmChanges={onConfirmChanges} projectName={projectDetails.name} onBackBtnListener={onBackBtnListener} importedVideoPath={ImportedVideoPath} projectDetails={allDetails} />
      ) : (
        <>
          <div className="row m-0 px-0 px-md-5 pt-4 justify-content-center">
            <div className="col-11  col-md-12 align-self-center mb-3">
              <Button className={styles.return_to_campaign} icon={<ArrowLeftOutlined />} onClick={() => {navigate("/projects")}} > Return to all campaigns </Button>
            </div>
            <div className="col-11 col-md-12 d-flex">
                <span className={styles.projectName} style={{ whiteSpace: "normal", overflow: "hidden",     textOverflow: "ellipsis",   display: "-webkit-box", WebkitBoxOrient: "vertical", display:"flex", WebkitLineClamp: 2   }}> {`${projectDetails?.name}`} </span>
                
                {/* {projectDetails.project_state == "Draft" && (<> <CompressOutlined onClick={handleEditClick} /></> )} */}
                <img src={editiconeditproject} style={{height:"20px"}} onClick={handleEditProjectNameClick} className={`${styles.edit_del_icon} ms-3 align-self-center`} />
                
                <img src={deleteiconeditproject} onClick={handleDeleteClick} className={`${styles.edit_del_icon} ms-3`}/>
                {/* {projectDetails.project_state == "Draft" && <Button className={styles.btn_outlined} onClick={handleEditClick}>Design Editor</Button>} */}
            </div>
            <div className={`col-11 col-md-12 ${styles.statusCompleted}`}> 
                {projectDetails?.project_state === "Completed" && ( <p> <i>&#10003;</i> Ready for Download </p> )}
            </div>
            <div className={`col-11 col-md-12 ${styles.statusDraft}`}> 
                {projectDetails?.project_state === "Draft" && ( <> <p> <i>&ndash;</i> Draft </p> <button className={styles.video_editor} onClick={handleEditClick}>VIDEO EDITOR &#x2192;</button> </> )}
            </div>
            <div className={`col-11 col-md-12 ${styles.statusProgress}`}> 
                {projectDetails?.project_state === "In Progress" && ( <p> <i className="fa-solid fa-circle-notch"></i> Campaign in progress - generating videos </p> )}
            </div>
            <div className="col-11 col-md-12 align-self-center d-flex " style={{flexWrap:"wrap"}}>
                {DOWNLOAD_TO_PC_STARTED ? ( <Button className={`${styles.btn_progress} mb-2`}> {" "}  <i className="fa-solid fa-circle-notch"></i>Downloading to device </Button> ) 
                                        : ( <Button className={`${styles.btn_outlined} mb-2`} onClick={handleDownloadToPC} disabled={projectDetails.project_state != "Completed"} > {" "}{projectDetails.project_state != "Completed" ? <img src={downloadicongrey} /> : <img src={downloadicon} />}Download all to device </Button>)}
                {DriveLink ?<Button className={`${styles.btn_copylink} mb-2`} onClick={()=>{navigator.clipboard.writeText(DriveLink).then(() => {message.success('Link copied to clipboard!');})}}><i className="fa-solid fa-share"></i> Copy link to Google Drive folder</Button> :
                    DOWNLOAD_TO_DRIVE_STARTED ? ( <Button className={`${styles.btn_progress} mb-2`}> <i className="fa-solid fa-circle-notch"></i> Uploading to Google Drive Started{" "} </Button> ) 
                    : ( <Button className={`${styles.btn_outlined} mb-2`} onClick={handleDownloadToDrive} disabled={projectDetails.project_state != "Completed"} > {" "} {projectDetails.project_state != "Completed" ? <img src={downloadicongrey} /> : <img src={downloadicon} />} Upload all to Google Drive </Button>)
                }
            </div>
          </div>

          <div className="row m-0 px-0 px-md-5 mt-4">
            <div className="col-12">
              <List header={ <div> <b>Generated Videos</b> </div> } bordered >
                {scrollerVideos.map((video) => ( <ProjectLinkCard key={video.id} videoLink={video} projectDetails={projectDetails} onDelete={() => onDelete(video.id)} created_at={projectDetails.created_at.slice(0, 10)} project_name={projectDetails.name} /> ))}
              </List>
            </div>
          </div>
          <RenameProjectButton isModalVisible={isModalVisible} handleCancel={()=>setIsModalVisible(false)} form={form} token={token} projectDetails={projectDetails} setShowSpinner={setShowSpinner} setIsModalVisible={setIsModalVisible} />
        </>
      )}
    </>
  );
};

export default SingleProjectPage;
