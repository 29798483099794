import axios from "axios";
import { DOMAIN_NAME } from "../values/DomainName";
import { message } from "antd";
import { setAuthToken, setLoggedIn } from "../redux/AuthToken/Action";
import { ENVOKE_USER_REGISTERED_EVENT } from "../values/Analytics";


export const API_SIGN_IN = async(setShowSpinner,email,password,dispatch)=>{
    setShowSpinner(true)
    try {
        const response = await axios.post(`${DOMAIN_NAME}/auth/signin/`, {
            email: email,
            password: password
          });
          dispatch(setAuthToken(response.data.token));
          dispatch(setLoggedIn(true));
        // message.success("Coupon applied successfully");
        return response.data
      } catch (error) {
        message.error("Wrong credentials");
        const errorMessage = error.response?.data?.message || error.message || "An error occurred";
      } finally {
        setShowSpinner(false);
      }
}

export const API_SEND_VERIFICATION_EMAIL = async (email, setToken, setShowConfirmationCodeForm, enableTimer,forgotPassword,setShowSpinner)=>{
    setShowSpinner(true)
    console.log('API_SEND_VERIFICATION_EMAIL')
    try {
        const response = await axios.post(`${DOMAIN_NAME}/auth/send_verification_email/`, {
            email: email,
            forgot_password:forgotPassword
          });
      
          setToken(response.data.code_token);
          if (response.status === 200) {
            setShowConfirmationCodeForm(true);
            message.success(`Verification code has been sent to ${email}`)
            enableTimer();
          }
          return response
      } catch (error) {
        const errorMessage = error.response?.data?.message || error.message || "An error occurred";
    message.error(errorMessage);
      } finally {
        setShowSpinner(false);
      }
}

export const API_SET_NEW_PASSWORD = async(setShowSpinner,email,newPassword,navigate)=>{
    setShowSpinner(true)
    try {
        const response = await axios.post(`${DOMAIN_NAME}/auth/set_new_password/`, {
          email,
          new_password: newPassword,
        });
        setShowSpinner(false);
        message.success('Password changed successfully');
        navigate('/account');
      } catch (error) {
        setShowSpinner(false);
        message.error(error);
      }
}

export const API_VERIFY_CODE_SIGNIN = async(code, token, dispatch, navigate,forgotPasswordEmail,setRemainingTries,setShowSpinner)=>{
    setShowSpinner(true)
    try {
        const response = await axios.post(`${DOMAIN_NAME}/auth/authenticate_verification_code/`, {
          verification_code: code,
          code_token: token,
        });
        if (response.status === 200) {
          dispatch(setAuthToken(token))
          navigate(`/resetPassword/${forgotPasswordEmail}`)
        }
        
      } catch (error) {
        setRemainingTries((prevTries) => {
            const newTries = prevTries - 1;
            if (newTries === 0) {
              message.error('You have reached the maximum number of tries.');
              navigate('/');
            } else {
              message.error(`Wrong code entered. You have ${newTries} tries left.`);
            }
            
          return newTries;
        });
      }finally{
        setShowSpinner(false)
      }
}

export const API_VERIFY_CODE_SIGNUP = async(code, token, dispatch, navigate,formValues,setRemainingTries,setShowSpinner)=>{
    setShowSpinner(true)
    try {
        const response = await axios.post(`${DOMAIN_NAME}/auth/authenticate_verification_code/`, {
          verification_code: code,
          code_token: token,
        });
        if (response.status === 200) {
            try {
                const signupResponse = await axios.post(`${DOMAIN_NAME}/auth/signup/`, {
                  email: formValues.email,
                  password: formValues.newPassword,
                  first_name: formValues.firstName,
                  last_name: " ",
                });
                message.success('Account created successfully!');
                ENVOKE_USER_REGISTERED_EVENT()
                dispatch(setAuthToken(signupResponse.data.token));
                dispatch(setLoggedIn(true));
                navigate('/projects');
              } catch (signupError) {
                message.error('Signup error occurred!');
              }
        }
        
      } catch (error) {
        setRemainingTries((prevTries) => {
            const newTries = prevTries - 1;
            if (newTries === 0) {
              message.error('You have reached the maximum number of tries.');
              navigate('/');
            } else {
              message.error(`Wrong code entered. You have ${newTries} tries left.`);
            }
            
          return newTries;
        });
      }finally{
        setShowSpinner(false)
      }
}