import axios from "axios";
import { DOMAIN_NAME } from "../values/DomainName";
import { message } from "antd";

export const API_SUBMIT_HELP_FORM = async (details, token,isLoggedIn) => {
  console.log(details, token);
  try {
    const data = {
      ...details,
    };
    console.log(details);
    if(isLoggedIn)
    {
        const response = await axios.post(
            `${DOMAIN_NAME}/helpdesk/make_request_for_login/`,
            data,
            {
              headers: {
                Authorization: token,
                "Content-Type": "multipart/form-data",
              },
            }
          );
    }
    else
    {
        const response = await axios.post(
            `${DOMAIN_NAME}/helpdesk/make_request_for_logout/`,
            data,
            {
              headers: {
                Authorization: token,
                "Content-Type": "multipart/form-data",
              },
            }
          );
    }
    message.success("Message sent successfully");
    // console.log(response);
  } catch (error) {
    let errorMessage = "An unknown error occurred";
    if (error.response && error.response.data && error.response.data.message) {
      errorMessage = error.response.data.message;
    }
    message.error(errorMessage);
  }
};
