import React, { useEffect } from 'react'
import Navbar from '../components/Navbar'
import { PAGE_TERMS } from '../values/Text'
import styles from './styles/InfoPages.module.css'
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import Footer from "../components/Footer";

const TermsOfService = () => {
    const { token, isLoggedIn } = useSelector((state) => state.authToken);
    useEffect(()=>{
        
    window.scrollTo({ top: 0, behavior: 'smooth' });
    },[])
  return (
    <div>
       {!isLoggedIn &&  <Navbar />}
      <Helmet>
        <title>Terms of Service | Cyber Visionaries</title>
      </Helmet>
      <div className="row m-0 justify-content-center">
        <div className="col-10">
        <p className={styles.heading}>Terms of Service</p>
          <p className={styles.text} dangerouslySetInnerHTML={{ __html: PAGE_TERMS }} ></p>
        </div>
      </div>
      {!isLoggedIn && <div className={`row m-0 px-2 py-3 p-lg-4 ${"footer_component"}`}>
        <Footer />
      </div>}
    </div>
  )
}

export default TermsOfService
