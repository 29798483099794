import axios from "axios";
import { DOMAIN_NAME } from "../values/DomainName";
import { message } from "antd";

export const GET_VIDEOS_LEFT = async(setShowSpinner,token)=>{
    setShowSpinner(true)
    try {
        const response = await axios.get(
          `${DOMAIN_NAME}/projectsettings/get_videos_left/`,
          {
            headers: {
              Authorization: token,
            },
          }
        );
        console.log(response.data);
        return response.data
      } catch (error) {
        message.error("Failed to fetch pricing models.");
        console.error(error);
      } finally {
        setShowSpinner(false);
      }
}


export const API_GET_WEBVIDEO_SETTINGS = async(setShowSpinner,token)=>{
    setShowSpinner(true);
    try {
      const response = await axios.get(
        `${DOMAIN_NAME}/projectsettings/get_webvideo_settings/`,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      return response.data
    } catch (error) {
      message.error("Failed to fetch settings.");
      console.error(error);
    } finally {
      setShowSpinner(false);
    }
}

export const API_SET_WEBVIDEO_SETTINGS = async(setShowSpinner,token,data,setSettingsChanged)=>{
    try {
        const response = await axios.post(
          `${DOMAIN_NAME}/projectsettings/set_webvideo_settings/`,
          data,
          {
            headers: {
              Authorization: token,
              "Content-Type": "application/json",
            },
          }
        );
        message.success("Settings confirmed successfully!");
        setSettingsChanged(false);
      } catch (error) {
        let errorMessage = "An unknown error occurred";
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          errorMessage = error.response.data.message;
        }
        message.error(errorMessage);
      } finally {
        setShowSpinner(false);
      }
}

