import React, { useEffect, useState } from "react";
import { Button, Modal, Input, Form, Spin } from "antd";
import axios from "axios";
import { useSelector } from "react-redux";
import pricing3_img from "../assets/pricing3.svg";
import './styles/Serialcode.css';
import { API_CREATE_CHECKOUT_SESSION } from "../api/api_payments";
import { DOMAIN_NAME } from "../values/DomainName";

const SerialCode = ({ isSerialCodeModalOpen, setisSerialCodeModalOpen }) => {
  const [serialCode, setSerialCode] = useState("");
  const [loading, setLoading] = useState(false);
  const [cardsData, setCardsData] = useState([]);
  const { token } = useSelector((state) => state.authToken);
  const [responseFromAPI, setResponseFromAPI] = useState({});
  const [showFreeCard, setShowFreeCard] = useState(false);
  const [showForm, setShowForm] = useState(true);
  const [showSpinner, setShowSpinner] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [claimFreeSerial, setClaimFreeSerial] = useState(false);
  const [modalWidth, setModalWidth] = useState(300); // Default width based on window width

  useEffect(() => {
    if (responseFromAPI.is_free) {
      setShowFreeCard(true);
      console.log(responseFromAPI);
    }
  }, [responseFromAPI]);

  useEffect(() => {
    const updateModalWidth = () => {
      const cardsCount = responseFromAPI?.pricing_plans?.length || 1.2;
      const cardWidth = 380; // Width of each card
      const gap = 20; // Gap between cards
      const windowWidth = window.innerWidth;
      const maxModalWidth = windowWidth * 0.8; // Modal width is 80% of window width
      const newWidth = Math.min(cardsCount * (cardWidth + gap), maxModalWidth);
      setModalWidth(newWidth);
    };

    updateModalWidth();

    // Add event listener to update modal width on window resize
    window.addEventListener('resize', updateModalWidth);
    return () => window.removeEventListener('resize', updateModalWidth);
  }, [responseFromAPI]);

  const handleCancel = () => {
    setisSerialCodeModalOpen(false);
    setCardsData([]);
  };

  const handleNext = async () => {
    setLoading(true);

    try {
      const response = await axios.post(
        `${DOMAIN_NAME}/marketing/validate_serial_code/`,
        { serial_code: serialCode.replace(/[\s-]/g, '') },
        {
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
          },
        }
      );
      setResponseFromAPI(response.data);
      setShowForm(false);
      console.log(response.data);
    } catch (error) {
      const errorMessage = error?.response?.data?.message || "An unknown error occurred";
      setErrorMessage(errorMessage);
    }

    setLoading(false);
  };

  const handlePlanClick = async (priceId, paymentType) => {
    await API_CREATE_CHECKOUT_SESSION(setShowSpinner, token, priceId, paymentType, null, 1);
  };

  function formatSerialCode(code) {
    // Remove non-word characters (if needed)
    const cleanedCode = code.replace(/\W/g, '');
  
    // Add hyphens after every 4 characters
    const formattedCode = cleanedCode.replace(/(.{4})(?=.)/g, '$1 - ');
  
    // Return the formatted code
    return formattedCode;
  }
  

  const handleSerialCodeChange = (e) => {
    // Get the current value from the input field
    const inputValue = e.target.value;

    // Format the serial code based on the current value
    const formattedCode = formatSerialCode(inputValue);

    // Update the state with the formatted serial code
    setSerialCode( e.target.value);
  };

  useEffect(() => {
    console.log('Component re-rendered');
  });

  return (
    <Modal
      closeIcon={false}
      open={isSerialCodeModalOpen}
      footer={null}
      maskClosable={true}
      onCancel={handleCancel}
      width={modalWidth} // Dynamic width
    >
      {showSpinner && <Spin size="large" style={{ position: "fixed", top: "50%", left: "50%" }} />}
      
      {showForm ? (
        <>
          <Form layout="vertical serial-form">
            <p className="redeem_serial_text">Redeem serial code</p>
            <Form.Item label="Enter Serial Code" required>
              <Input
                value={formatSerialCode(serialCode)}
                onChange={handleSerialCodeChange}
                placeholder="XXXX - XXXX - XXXX - XXXX"
                className="serial-input"
                style={{ borderColor: errorMessage ? "red" : undefined }}
              />
              {errorMessage && (
                <p style={{ color: "red", margin: "5px 0px 0px 0px" }}>{errorMessage}</p>
              )}
            </Form.Item>
            <Button
              type="primary"
              className="serial-get-started-button"
              onClick={handleNext}
              loading={loading}
            >
              Next
            </Button>
          </Form>
        </>
      ) : (
        <div className="row m-0">
          <h2 className="claim_your_offer_text mb-4">Claim your offer</h2>
          
          {showFreeCard ? (
            <div className="col-12 px-2">
              <div className="row m-0">
                <div className="col-12 px-4 serial-pricing-card justify-content-start d-block text-center">
                  <img src={pricing3_img} alt="Product Image" />
                  <p className="serial_code_pricing-name">VIDEO TOKENS</p>
                  <p className="serial-pricing-amount">$0</p>
                  <p className="serial-info-text-upper">Already Paid</p>
                  <div className="serial-info-container">
                    <div className="serial-info-item mb-5">
                      <i className="icon-class fa-solid fa-check"></i> 
                      <p className="serial-info-text">{responseFromAPI?.videos_count} prospecting videos per month</p>
                    </div>
                    {claimFreeSerial ? (
                      <button className="serial-get-started-button-yellow mt-5">
                        Offer claimed successfully
                      </button>
                    ) : (
                      <button className="serial-get-started-button mt-5" onClick={() => setClaimFreeSerial(true)}>
                        Claim
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="pricing-plans-container p-0">
              {responseFromAPI?.pricing_plans?.map((item, index) => (
                <div
                  className="serial-pricing-card "
                  style={{ width: "350px" }}
                  key={item.stripe_price_id}
                >
                  <img src={pricing3_img} alt="Product Image" />
                  <p className="serial_code_pricing-name m-0">{item.name}</p>
                  <div className="text-center">
                    <div style={{ position: 'relative', display: 'inline-block' }}>
                      {item.discounted_price && (
                        <div style={{ position: 'absolute', top: '5px', right: '-55px', backgroundColor: 'rgba(255, 152, 30, 1)', color: '#fff', padding: '2px 10px', borderRadius: '50px', fontSize: '12px', fontWeight: '600' }}>
                          -{Math.round((1 - +item.discounted_price / +item.price) * 100)}%
                        </div>
                      )}
                      <p className="serial-pricing-amount text-center">
                        {+item.discounted_price ? (
                          <>
                            <span className="discounted-price align-self-center">${+item.price}</span>
                            <span className="serial-pricing-amount">${+item.discounted_price}</span>
                          </>
                        ) : (
                          <>
                            <span className="discounted-price align-self-center">${index === 0 ? "74" : "194"}</span>
                            <span>${item.price}</span>
                          </>
                        )}
                      </p>
                    </div>
                  </div>
                  <p className="heading-text-2">
                    {item.payment_type === "recurring" && item.subscription_type === "month" && "Billed Monthly"}
                    {item.payment_type === "recurring" && item.subscription_type === "year" && "Billed Yearly"}
                    {item.payment_type === "one_time" && "One-Time Payment"}
                  </p>
                  <div className="info-container">
                      {item.description.split("\n")?.map((sentence, idx) => (
                        <div className="info-item" key={idx}>
                          <i className="icon-class fa-solid fa-check"></i>{" "}
                          <p className="info-text">{sentence}</p>
                        </div>
                      ))}
                      <p className="info-text">
                        {`Pay ${item.discounted_price}$ / month for ${responseFromAPI?.months} month(s), followed by the standard pricing thereafter`}
                      </p>
                    </div>
                    {item.is_active_model ? (
                      <button className="serial-get-started-button-yellow">Offer claimed successfully</button>
                    ) : (
                      <button className="serial-get-started-button" onClick={() => handlePlanClick(item.stripe_price_id, item.payment_type)}>
                        Claim
                      </button>
                    )}
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </Modal>
  );
};

export default SerialCode;
