import React from "react";
import { PlusOutlined } from "@ant-design/icons";
import styles from "./styles/AddNewProject.module.css";
import { useNavigate } from "react-router-dom";

const AddNewProject = () => {
    const navigate = useNavigate()
  return (
    
    <div className={`col-12 px-2 col-xl-4  col-xxl-3  col-md-6 px-2 mt-4`} onClick={()=>navigate('/newProject')}>
      <div className="row justify-content-center m-0">
        <div className="col-12 align-items-center">
        <div className={`row px-2 mt-2 ${styles.projectContainer} justify-content-center`}>
          <div className={`col-11 ${styles.innerContainer} text-center align-self-center`} >
            {/* <PlusOutlined className={styles.plusIcon}/> */}
            <p className={styles.addProject}>&#43; New Campaign</p>
          </div>
          
        </div>
      </div>
      </div>
    </div>
  );
};

export default AddNewProject;
