import React, { useState, useEffect } from 'react';
import { LockOutlined, MailOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Form, Input, message, Checkbox, Spin } from 'antd';
import { useNavigate, Link } from 'react-router-dom';
import './styles/Signup.css';
import axios from 'axios';
import { useDispatch } from "react-redux";
import signinImage from '../assets/Frame 81.svg';
import { setAuthToken, setLoggedIn } from '../redux/AuthToken/Action';

import PasswordStrengthBar from 'react-password-strength-bar';
import { startTimer, sendVerificationEmail, verifyCode, passwordRules, signUppasswordRules } from '../values/AuthHelpers';
import { DOMAIN_NAME } from '../values/DomainName';
import { API_SEND_VERIFICATION_EMAIL, API_VERIFY_CODE_SIGNUP } from '../api/api_authentication';
import FacebookLoginBtn from './FacebookLoginBtn';
import GoogleLoginBtn from './GoogleLoginBtn';
import { isPasswordValid } from '../values/Helpers';

const Signup = ({ signinEnabler }) => {
  const [password, setPassword] = useState(''); // State for password strength
  const [confirmedPasswordBlurred, setConfirmedPasswordBlurred] = useState(false); // Track if the confirmedPassword field has been blurred
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [formValues, setFormValues] = useState({});
  const [showConfirmationCodeForm, setShowConfirmationCodeForm] = useState(false);
  const [timer, setTimer] = useState(4);
  const [timerRunning, setTimerRunning] = useState(false);
  const [showTimer, setShowTimer] = useState(false);
  const [token, setToken] = useState('');
  const [code, setCode] = useState('');
  const [remainingTries, setRemainingTries] = useState(3);
  const [ShowSpinner, setShowSpinner] = useState(false);
  const [agreedToTerms, setAgreedToTerms] = useState(false);
  const [consentPromotions, setConsentPromotions] = useState(false);

  useEffect(() => {
    if (timerRunning) {
      return startTimer(timer, setTimer, setShowTimer, setTimerRunning);
    }
  }, [timer, timerRunning]);

  const validateConfirmedPassword = (value) => {
    if (confirmedPasswordBlurred) {
      const newPassword = form.getFieldValue('newPassword');
      const confirmedPassword = form.getFieldValue('confirmedPassword');
      if (confirmedPassword !== newPassword) {
        return Promise.reject(new Error('Passwords do not match'));
      }
    }
    return Promise.resolve();
  };

  const onFinish = async (values) => {
    setFormValues(values);

    const passwordResult = isPasswordValid(values.newPassword);
    if (passwordResult.valid) {
      if (values.newPassword !== values.confirmedPassword) {
        form.setFields([{ name: "confirmedPassword", errors: ["Passwords do not match"], }]);
      } else {
        const response = await API_SEND_VERIFICATION_EMAIL(values.email, setToken, setShowConfirmationCodeForm, enableTimer, false, setShowSpinner);
      }
    } else {
      message.error(passwordResult.reason);
    }
  };

  const handleConfirmationCode = async () => {
    await API_VERIFY_CODE_SIGNUP(code, token, dispatch, navigate, formValues, setRemainingTries, setShowSpinner);
  };

  const enableTimer = () => {
    setShowTimer(true);
    setTimer(4);
    setTimerRunning(true);
  };

  const handleCodeChange = (e) => {
    setCode(e.target.value);
  };

  const handleNewPasswordChange = (e) => {
    setFormValues({ ...formValues, newPassword: e.target.value });
    form.setFieldsValue({ newPassword: e.target.value });
    setPassword(e.target.value); // Update the password state for strength checking
  };

  const handleConfirmedPasswordChange = (e) => {
    setFormValues({ ...formValues, confirmedPassword: e.target.value });
    form.setFieldsValue({ confirmedPassword: e.target.value });
  };

  const handleBlur = (field) => {
    if (field === 'confirmedPassword') {
      setConfirmedPasswordBlurred(true);
      form.validateFields(['confirmedPassword']);
    }
  };

  const handleAgreedToTermsChange = (e) => {
    setAgreedToTerms(e.target.checked);
  };

  const handleConsentPromotionsChange = (e) => {
    setConsentPromotions(e.target.checked);
  };

  return (
    <>
      {ShowSpinner && <Spin fullscreen />}
      <div className={`row m-0 justify-content-evenly mainRow`}>
        <div className={`col-12 col-xl-5 col-md-9 col-sm-10 p-0 align-self-center ${'mainCol'} text-center`}>
          <p className={'createAccountHeading'}>Create New Account</p>
          <div className="row m-0 justify-content-center">
            <div className="col-11 align-self-center">
              {showConfirmationCodeForm ? (
                <>
                  <Form onFinish={handleConfirmationCode} className={'loginForm'}>
                    <Form.Item name="code" rules={[{ required: true, message: 'Please enter the code received in your email.' }]}>
                      <Input onChange={handleCodeChange} className={'loginInput1'} placeholder="Enter code..." />
                    </Form.Item>
                    <Button type="primary" htmlType="submit" disabled={!code.trim()} className={'submitButton'}>Submit</Button>
                  </Form>
                  {showTimer && <p className={'resendCode'}>Resend Code in: {timer} seconds</p>}
                  {!timerRunning && (
                    <Button onClick={async () => { await API_SEND_VERIFICATION_EMAIL(formValues.email, setToken, setShowConfirmationCodeForm, enableTimer, false, setShowSpinner); }} className={'resendCodeBtn'}>Resend Code</Button>
                  )}
                </>
              ) : (
                <>
                  <Form form={form} name="signup_form" initialValues={{ remember: true }} validateTrigger="onBlur" onFinish={onFinish} className={'loginForm'}>
                    <Form.Item name="firstName" rules={[{ required: true, message: 'Please enter name' }]}>
                      <Input prefix={<UserOutlined />} placeholder="Name" className={'loginInput1'} />
                    </Form.Item>
                    <Form.Item name="email" validateTrigger={['onBlur']} rules={[{ required: true, message: 'Please input your Email!' }, { type: 'email', message: 'Please enter a valid email address!' }]}>
                      <Input prefix={<MailOutlined />} placeholder="Email" className={'loginInput1'} />
                    </Form.Item>
                    <Form.Item name="newPassword" rules={signUppasswordRules} validateTrigger="onBlur">
                      <Input.Password prefix={<LockOutlined />} onChange={handleNewPasswordChange} className={'loginInput1'} placeholder="Enter new Password..." />
                    </Form.Item>
                    {password?.length > 0 && <PasswordStrengthBar password={password} />}
                    <Form.Item name="confirmedPassword" rules={[{ validator: validateConfirmedPassword }]} validateTrigger="onBlur">
                      <Input.Password prefix={<LockOutlined />} onChange={handleConfirmedPasswordChange} onBlur={() => handleBlur('confirmedPassword')} className={'loginInput1'} placeholder="Confirm Password..." />
                    </Form.Item>
                    <div className='text-start'>
                      <Form.Item name="agreedToTerms" className={'formItem'} valuePropName="checked" rules={[{ required: true, message: 'You must agree to the terms and privacy policy.' }]}>
                        <Checkbox onChange={handleAgreedToTermsChange} className={'agreements'}>
                          By signing up you agree to the <Link to="/termsOfService">terms of service</Link> and <Link to="/privacyPolicy">privacy policy</Link>.
                        </Checkbox>
                      </Form.Item>
                      <Form.Item name="consentPromotions" className={'formItem'} valuePropName="checked">
                        <Checkbox onChange={handleConsentPromotionsChange} className={'agreements'}>
                          I agree to receive gifts and promotional material to my inbox.
                        </Checkbox>
                      </Form.Item>
                    </div>
                    <Form.Item>
                      <Button htmlType="submit" className={'home_page_btn_filled'} disabled={!agreedToTerms} style={{ color: (!agreedToTerms ) ? 'white' : 'defaultColor' }}>Create Account</Button>
                    </Form.Item>
                  </Form>
                  <p className={'createAccountBtn'} onClick={() => signinEnabler()}>
                    Already have an account? <b>SIGN IN</b>
                  </p>
                  <div className={`${'or'} mb-4`}>or</div>
                  <div className={`${'socialLogin'} row justify-content-center`}>
                    <div className="col-8 text-center">
                      <div className="">
                        <FacebookLoginBtn onLoginSuccess={(token) => setToken(token)} />
                        <GoogleLoginBtn onLoginSuccess={(token) => setToken(token)} />
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        <div className={`col-12 col-xl-5 p-0 d-none d-xl-block align-self-center text-center `}>
           <div className="row m-0 align-self-center">
            <div className="col-12 signinImage_parent align-self-center">
                <span className='star_component'>&#9733;&#9733;&#9733;&#9733;&#9733; 5.0 rating</span>
                <div className='rating_text'>
                As a marketer always looking for new ways to attract potential clients, I have to say that the video creator has been a game-changer for me and my team. This tool has streamlined the process of making personalized videos in bulk, helping us generate more leads effectively. And the video editor feature is great. Thanks!
                </div>
                <div className='name_text'>
                James  Stewart
                </div>
                <div className='position_text'>
                Industry: Digital Marketing
                </div>
            </div>
           </div>
        </div>
      </div>
    </>
  );
};

export default Signup;
