import axios from "axios";
import { DOMAIN_NAME } from "../values/DomainName";
import { message } from "antd";
import moment from 'moment';
import { gapi } from "gapi-script"; // Import Google API script
import { CONVERT_SCROLLERVIDEOS_TO_URL_AND_NAMES } from "../values/Helpers";

export const API_SAVE_DRAFT = async (details, token) => {
  try {
    const url = window.location.href;
    const id = url.substring(url.lastIndexOf("/") + 1);

    const data = {
      ...details,
      project_id: id,
    };
    console.log(details);
    const response = await axios.post(
      `${DOMAIN_NAME}/webvideo/update_project_attributes/`,
      data,
      {
        headers: {
          Authorization: token,
          "Content-Type": "multipart/form-data",
        },
      }
    );
    message.success("Draft saved successfully");
    console.log(response);
  } catch (error) {
    let errorMessage = "An unknown error occurred";
    if (error.response && error.response.data && error.response.data.message) {
      errorMessage = error.response.data.message;
    }
    message.error(errorMessage);
  }
};

export const API_CREATE_PROJECT = async ( setShowSpinner, token, combinedValues, seconds,start_video_from, fileList2, navigate) => {
  setShowSpinner(true);
  const formData = new FormData();
  formData.append("project_name", combinedValues.projectName);
  formData.append("output_video_duration", seconds);
  formData.append("start_video_from",start_video_from);
  formData.append("web_links", [combinedValues.links]);
  if (fileList2.length > 0) {
    formData.append("imported_video", fileList2[0].originFileObj);
  } else {
    formData.append("imported_video", null);
  }
console.log("CALLING BACKEND API")
  try {
    const response = await axios.post(
      `${DOMAIN_NAME}/webvideo/create_project/`,
      formData,
      {
        headers: {
          Authorization: token,
          "Content-Type": "multipart/form-data",
        },
      }
    );
    const projectDetails = response.data;
    navigate(`/new/project/${projectDetails?.id}`, {
      state: { is_newly_created: true },
    });
  } catch (error) {
    message.error("Failed to create project.");
    console.error(error);
  } finally {
    setShowSpinner(false);
    console.log("BACKEND API RESPONDED")
  }
};

export const API_RENAME_PROJECT = async ( setShowSpinner, setIsModalVisible, token, projectDetails, values) => {
  setIsModalVisible(false);
  setShowSpinner(true);
  try {
    const response = await axios.put(
      `${DOMAIN_NAME}/webvideo/rename_project/`,
      { project_id: projectDetails?.id, updated_name: values.name },
      {
        headers: {
          Authorization: token,
        },
      }
    );
    message.success(response.data.message);
    return true;
  } catch (error) {
    let errorMessage = "An unknown error occurred..";
    if (error.response && error.response.data && error.response.data.message) {
      errorMessage = error.response.data.message;
    }
    message.error(errorMessage);
  } finally {
    setShowSpinner(false);
  }
};

export const API_DELETE_SCROLLER_VIDEO = async ( setShowSpinner, token, linkId, setScrollerVideos ) => {
  setShowSpinner(true);
  try {
    const response = await axios.delete(
      `${DOMAIN_NAME}/webvideo/delete_scrollervideo/`,
      {
        data: { scroller_video_id: linkId },
        headers: {
          Authorization: token,
        },
      }
    );
    message.success(response.data.message);

    setScrollerVideos((prevVideos) =>
      prevVideos.filter((video) => video.id !== linkId)
    );
  } catch (error) {
    message.error(error.response?.data?.message);
  } finally {
    setShowSpinner(false);
  }
};

export const API_START_PROJECT = async (token, data, navigate) => {
    console.log(data)
  const project_id_from_url = window.location.href.match(/project\/(\d+)/)[1];
  navigate(`/project/${project_id_from_url}`);
  try {
    const response = axios
      .post(`${DOMAIN_NAME}/webvideo/start_project/`, data, {
        headers: {
          Authorization: token,
          "Content-Type": "multipart/form-data",
        },
      })
  } catch (error) {
    message.error(error.response?.data?.message);
  } finally{
    window.location.reload()
  }
};

//! used for getting all videos in a project
export const API_GET_PROJECT_VIDEOS = async (
  token,
  id,
  setShowSpinner,
  setprojectDetails,
  setScrollerVideos,
  setImportedVideoPath,
  setIsEditing,
  setProjectTitle
) => {
  setShowSpinner(true);
  try {
    const response = await axios.get(
      `${DOMAIN_NAME}/webvideo/get_project_videos/`,
      {
        params: { project_id: id },
        headers: {
          Authorization: token,
        },
      }
    );
    setprojectDetails(response.data);
    
    setProjectTitle(`${response.data?.name} | Cyber Visionaries`)
    setScrollerVideos(response.data.scrollervideos);
    setImportedVideoPath(response.data.imported_video);

    if (window.location.href.includes("new")) {
      setIsEditing(true);
    }
    setShowSpinner(false);
  } catch (error) {
    message.error("Failed to fetch project details.");
    console.error(error);
  }
};

//! used for draft attributes
export const API_FETCH_PROJECT_ATTRIBUTES = async (
  id,
  token,
  setAllDetails
) => {
  try {
    const response = await axios.get(
      `${DOMAIN_NAME}/webvideo/fetch_project_attributes/`,
      {
        params: { project_id: id },
        headers: {
          Authorization: token,
        },
      }
    );
    setAllDetails(response.data);
    console.log(response.data)
  } catch (error) {
    console.log(error);
  }
};

export const API_FETCH_PROJECTS = async(setShowSpinner,token)=>{
    setShowSpinner(true);
    try {
        const response = await axios.get(`${DOMAIN_NAME}/webvideo/get_projects/`, {
            headers: {
                'Authorization': token,
            },
        });
        const sortedProjects = response.data.sort((a, b) => {
            // Convert dates to moment objects for comparison
            const dateA = moment(a.created_at);
            const dateB = moment(b.created_at);
            // Compare dates
            return dateB - dateA; // Change to dateA - dateB for ascending order
        });

        return sortedProjects
    } catch (error) {
        message.error('Failed to fetch projects.');
    } finally {
        setShowSpinner(false);
    }
}

export const API_DELETE_PROJECT = async(setShowSpinner,id,token)=>{
    setShowSpinner(true);
    try {
        const response = await axios.delete(
            `${DOMAIN_NAME}/webvideo/delete_project/`,
            {
              data: { project_id: id },
              headers: {
                Authorization: token,
              },
            })
        return true
    } catch (error) {
        message.error('Failed to fetch projects.');
        return false
    } finally {
        setShowSpinner(false);
    }
}