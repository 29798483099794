// src/redux/Reducer.js

import { SET_COOKIE_CONSENT } from './Types';

const initialState = {
  cookieConsentChecked: false,  // New state for cookie consent
};

const cookieConsentReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_COOKIE_CONSENT:
      return {
        ...state,
        cookieConsentChecked: action.payload,  // Handle new action
      };
    default:
      return state;
  }
};

export default cookieConsentReducer;
