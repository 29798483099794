import { combineReducers } from "redux";
import authReducer from "./AuthToken/Reducer";
import cookieConsentReducer from "./CookieConsent/Reducer";

const rootReducer = combineReducers({
  authToken :authReducer,
  cookieConsent:cookieConsentReducer
});

export default rootReducer;
