import React from 'react';
import styles from './styles/CustomModal.module.css'; // Create and import your custom CSS module

const CustomModal = ({ title, visible, onOk, onCancel, children, okText }) => {
  if (!visible) return null;

  return (
    <div className={`${styles.modalOverlay} row justify-content-center`}>
      <div className={`${styles.modalContent} col-10`}>
        <div className={`${styles.modalHeader} text-center `} >
          <h3 className='text-center'>{title}</h3>
          <button className={styles.closeButton} onClick={onCancel}>✕</button>
        </div>
        <div className={styles.modalBody}>
          {children}
        </div>
        <div className={styles.modalFooter}>
          <button className={styles.okButton} onClick={onOk}>{okText}</button>
        </div>
      </div>
    </div>
  );
};

export default CustomModal;
