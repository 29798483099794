// src/utils/calculations.js

import { API_SAVE_DRAFT } from "../api/api_project";

export const calculateNewPosition = (
  clientX,
  clientY,
  offsetX,
  offsetY,
  outerWidth,
  outerHeight
) => {
  let newX = ((clientX - (offsetX * outerWidth) / 100) * 100) / outerWidth;
  let newY = ((clientY - (offsetY * outerHeight) / 100) * 100) / outerHeight;

  // Ensure the inner div stays within the boundaries of the outer div
  newX = Math.max(
    0,
    Math.min(newX, 100 - (outerHeight * 0.3 * 100) / outerWidth)
  ); // Adjust for the width of inner div
  newY = Math.max(
    0,
    Math.min(newY, 100 - (outerHeight * 0.3 * 100) / outerHeight)
  ); // Adjust for the height of inner div

  return { x: newX, y: newY };
};

export const calculateBorderColorWithAlpha = (borderColor, alphaValue) => {
  const alpha = Math.round(alphaValue * 255);
  return `${borderColor}${alpha.toString(16).toUpperCase()}`; // Append alpha value to border color
};

export const calculateScreenShotValues = (width, height) => {
  let sswidth, ssheight;

  let maxDimension = window.innerWidth > 1400 ? 60 : 75; //!-------------
  if (+width > +height) {
    const ratio = height / width;
    sswidth = `${maxDimension}vw`;
    ssheight = `${maxDimension * ratio}vw`;
  } else {
    const ratio = width / height;
    ssheight = `${maxDimension}vw`;
    sswidth = `${maxDimension * ratio}vw`;
  }
  return { sswidth, ssheight };
};

export const getVideoDimensions = async (videoUrl) => {
  try {
    // Create a new video element
    const videoElement = document.createElement("video");

    // Set the video source to the provided URL
    videoElement.src = videoUrl;

    // Wait for the metadata to load
    await new Promise((resolve, reject) => {
      videoElement.addEventListener("loadedmetadata", resolve);
      videoElement.addEventListener("error", reject);
    });

    // Get the video's natural width and height
    const width = videoElement.videoWidth;
    const height = videoElement.videoHeight;

    // Remove the video element from the DOM
    videoElement.remove();

    return { width, height };
  } catch (error) {
    return { width: 0, height: 0 };
  }
};
export const saveDraftHandler = async ({
  token = "",
  width = 0,
  height = 0,
  outerSize = 0,
  position = { x: 0, y: 0 },
  borderWeight = 0,
  borderColor = "",
  alphaValue = 0,
  borderRadius = 0,
  cropped_image_bottom_left = { x: 0, y: 0 },
  cropped_image_bottom_right = { x: 0, y: 0 },
  cropped_image_top_left = { x: 0, y: 0 },
  cropped_image_top_right = { x: 0, y: 0 },
  initialDelay = 0,
  startScrolling = false,
  randomScrolling = false,
  scrollingGap = 0,
  muteVideo = false,
  DisableScroll = false,
}) => {
  const tl_x = Math.round((+width / 100) * position?.x);
  const tl_y = Math.round((+height / 100) * position?.y);

  const videoWidth = Math.round(height > width ? width * 0.3 : height * 0.3);
  const videoHeight = Math.round(height > width ? width * 0.3 : height * 0.3);

  const changesObject = {
    screenshot_height: Math.round(+height),
    screenshot_width: Math.round(+width),
    border_weight: Math.min(
      Math.floor(((+borderWeight * 4) / videoWidth) * 100), //border weight will be 25% of imported video width
      50
    ),
    border_color: borderColor,
    border_transparency: alphaValue,
    border_radius: Math.round(+borderRadius),
    imported_video_pos_tl: { x: tl_x, y: tl_y }, // Top-left
    imported_video_pos_bl: { x: tl_x, y: tl_y + videoHeight }, // Bottom-left
    imported_video_pos_br: { x: tl_x + videoWidth, y: tl_y + videoHeight }, // Bottom-right
    imported_video_pos_tr: { x: tl_x + videoWidth, y: tl_y }, // Top-right
    imported_video_bl: {
      x: Math.round(cropped_image_bottom_left.x),
      y: Math.round(cropped_image_bottom_left.y),
    },
    imported_video_br: {
      x: Math.round(cropped_image_bottom_right.x),
      y: Math.round(cropped_image_bottom_right.y),
    },
    imported_video_tl: {
      x: Math.round(cropped_image_top_left.x),
      y: Math.round(cropped_image_top_left.y),
    },
    imported_video_tr: {
      x: Math.round(cropped_image_top_right.x),
      y: Math.round(cropped_image_top_right.y),
    },
    initial_delay: initialDelay,
    scroll_starting: startScrolling,
    random_scrolling: randomScrolling,
    scroll_gap: scrollingGap,
    mute_video: muteVideo,
    disable_scroll: DisableScroll,
  };
  API_SAVE_DRAFT(changesObject, token);
};
export const applyChangesHandler = async ({
  width,
  height,
  outerSize,
  position,
  borderWeight,
  borderColor,
  alphaValue,
  borderRadius,
  cropped_image_bottom_left,
  cropped_image_bottom_right,
  cropped_image_top_left,
  cropped_image_top_right,
  initialDelay,
  startScrolling,
  randomScrolling,
  onConfirmChanges,
  scrollingGap,
  muteVideo,
  DisableScroll,
}) => {
  // Calculate top-left coordinates in pixels
  const tl_x = Math.round((+width / 100) * position.x);
  const tl_y = Math.round((+height / 100) * position.y);

  // Dimensions of the imported video
  const videoWidth = Math.round(height > width ? width * 0.3 : height * 0.3);
  const videoHeight = Math.round(height > width ? width * 0.3 : height * 0.3);

  const changesObject = {
    screenshot_height: Math.round(+height),
    screenshot_width: Math.round(+width),
    border_weight: Math.min(
      Math.floor(((+borderWeight * 4) / videoWidth) * 100), //border weight will be 25% of imported video width
      50
    ),
    border_color: borderColor,
    border_transparency: alphaValue,
    border_radius: Math.round(+borderRadius),
    imported_video_pos_tl: { x: tl_x, y: tl_y }, // Top-left
    imported_video_pos_bl: { x: tl_x, y: tl_y + videoHeight }, // Bottom-left
    imported_video_pos_br: { x: tl_x + videoWidth, y: tl_y + videoHeight }, // Bottom-right
    imported_video_pos_tr: { x: tl_x + videoWidth, y: tl_y }, // Top-right
    imported_video_bl: { x: Math.round(cropped_image_bottom_left.x), y: Math.round(cropped_image_bottom_left.y), },
    imported_video_br: { x: Math.round(cropped_image_bottom_right.x), y: Math.round(cropped_image_bottom_right.y), },
    imported_video_tl: { x: Math.round(cropped_image_top_left.x), y: Math.round(cropped_image_top_left.y), },
    imported_video_tr: { x: Math.round(cropped_image_top_right.x), y: Math.round(cropped_image_top_right.y), },
    initial_delay: initialDelay,
    scroll_starting: startScrolling,
    random_scrolling: randomScrolling,
    scroll_gap: scrollingGap,
    mute_video: muteVideo,
    disable_scroll: DisableScroll,
  };

  onConfirmChanges(changesObject);
};

// resizeHandler.js
// resizeHandlers.js
export const handleResize = (position, setOuterSize, setPosition) => {
  const outerDiv = document.getElementById("outer-div");

  if (!outerDiv) {
    return;
  }

  const rect = outerDiv.getBoundingClientRect();
  setOuterSize({ width: rect.width, height: rect.height });

  const innerDivWidth =
    rect.height > rect.width ? rect.width * 0.3 : rect.height * 0.3;
  const innerDivHeight =
    rect.height > rect.width ? rect.width * 0.3 : rect.height * 0.3;

  if (position === "topLeft") {
    setPosition({ x: 0, y: 0 });
  } else if (position === "topRight") {
    setPosition({ x: 100 - (innerDivWidth * 100) / rect.width, y: 0 });
  } else if (position === "bottomRight") {
    setPosition({
      x: 100 - (innerDivWidth * 100) / rect.width,
      y: 100 - (innerDivHeight * 100) / rect.height,
    });
  } else {
    setPosition({ x: 0, y: 100 - (innerDivHeight * 100) / rect.height });
  }
};

export const INITIAL_HEIGHT_AND_WIDTH = (height, width) => {
  let screenshotWidth = 35;

  if (window.innerWidth < 1200) {
    screenshotWidth = 70;
  }
  if (height > width) {
    const ratio = height / width;
    const screenshot_height = screenshotWidth * ratio;
    const screenshot_width = screenshotWidth;
    return { screenshot_height, screenshot_width };
  } else if (width > height) {
    const ratio = width / height;
    const screenshot_width = screenshotWidth;
    const screenshot_height = screenshotWidth / ratio;
    return {
      screenshot_height: parseInt(screenshot_height),
      screenshot_width: parseInt(screenshot_width),
    };
  } else {
    return { screenshotWidth, screenshotWidth };
  }
};
