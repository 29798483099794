import React from "react";
import { LoginSocialFacebook } from "reactjs-social-login";
import styles from './styles/FacebookLoginButton.module.css';
import { useState } from "react";
import { message } from "antd";
import axios from "axios";
import { Spin } from "antd";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setAuthToken, setLoggedIn } from "../redux/AuthToken/Action";
import { DOMAIN_NAME } from "../values/DomainName";

const FacebookLoginBtn = ({ onLoginSuccess }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [showSpinner, setShowSpinner] = useState(false);

  const sign_in_using_facebook = async (data) => {
    setShowSpinner(true);
    console.log(data);
    const name = data.name.split(' ');
    console.log(name);

    try {
      const response = await axios.post(`${DOMAIN_NAME}/auth/social_signin/`, {
        email: data.email,
        first_name: name[0],
        last_name: name[1],
      });
      dispatch(setAuthToken(response.data.token));
      dispatch(setLoggedIn(true));
      
      navigate('/projects')
    } catch (error) {
      let errorMessage = 'An unknown error occurred';
      if (error.response && error.response.data && error.response.data.message) {
        errorMessage = error.response.data.message;
      }
      message.error(errorMessage);
    } finally {
      setShowSpinner(false);
    }
  };

  return (
    <div className={styles.facebookLoginWrapper}>
      {showSpinner && <Spin fullscreen />}
      <LoginSocialFacebook
        appId="1658454738262017"
        onResolve={(res) => {
          console.log("LOGIN SUCCESS! Current user:", res.data);
          sign_in_using_facebook(res.data);
          const accessToken = res.data.accessToken;
          onLoginSuccess(accessToken);
        }}
        onReject={(error) => {
          console.log(error);
        }}
      >
        <CustomFacebookLoginButton />
      </LoginSocialFacebook>
    </div>
  );
};

// Custom Button Component
const CustomFacebookLoginButton = () => {
  return (
    <button className={styles.customFacebookLoginButton}>
      <i class="fa-brands fa-facebook-f me-2"></i>   Log in with Facebook
    </button>
  );
};

export default FacebookLoginBtn;
