import React, { useState, useEffect, useRef } from "react";
import Cropper from "react-easy-crop";
import { Button, Form, Input, Modal, message, Spin } from "antd";

const ImageCropper = ({ media, onCropComplete }) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const videoRef = useRef(null);
  const canvasRef = useRef(null);

  const [ShowSpinner, setShowSpinner] = useState(false);
  useEffect(() => {
    const isVideo = media.endsWith(".mp4");
    if (isVideo) {
      const videoObj = document.createElement("video");
      videoObj.src = media;
      videoObj.onloadeddata = () => {
        videoRef.current = videoObj;
      };
    }
  }, [media]);

  const onCropChange = (crop) => {
    setCrop(crop);
  };

  const onZoomChange = (zoom) => {
    setZoom(zoom);
  };

  const onCropCompleted = (_, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const onCropReset = () => {
    setCrop({ x: 0, y: 0 });
    setZoom(1);
    setCroppedAreaPixels(null);
  };
  const getCroppedVideo = async () => {
    if (croppedAreaPixels && videoRef.current) {
      const { x, y, width, height } = croppedAreaPixels;

      // Call the onCropComplete function with the cropped area data and video URL
      onCropComplete({
        coordinates: { x, y, width, height },
      });
    }
  };

  return (
    <>
      <div className="row m-0 mt-5 justify-content-center">
        <div className="col-10 col-md-6">
          <div
            style={{ position: "relative", width: "300px", height: "300px" }}
          >
            <Cropper
              video={media}
              crop={crop}
              zoom={zoom}
              aspect={1 / 1}
              onCropChange={onCropChange}
              onZoomChange={onZoomChange}
              onCropComplete={onCropCompleted}
            />
          </div>
          <button
            onClick={onCropReset}
            style={{
              width: "140px",
              marginTop: "10px",
              backgroundColor: "#880000",
              color: "white",
              fontWeight: 500,
              padding: "5px 0px",
              borderRadius: "10px",
            }}
          >
            Reset
          </button>
          <button
            style={{
              width: "140px",
              marginTop: "10px",
              backgroundColor: "#00a1e3",
              color: "white",
              fontWeight: 500,
              padding: "5px 0px",
              borderRadius: "10px",
              marginLeft: "20px",
            }}
            onClick={async () => {
              await getCroppedVideo();
            }}
          >
            Crop
          </button>
          <video ref={videoRef} style={{ display: "none" }} src={media} />
        </div>
      </div>
    </>
  );
};

export default ImageCropper;