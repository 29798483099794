import React from 'react';
import { Modal, Form, Input } from 'antd';
import { API_RENAME_PROJECT } from '../api/api_project';

const RenameProjectButton = ({ isModalVisible,  handleCancel, form, token, projectDetails,setShowSpinner,setIsModalVisible,}) => {
    const handleOk = async () => {
        const values = await form.validateFields();
        if(await API_RENAME_PROJECT(setShowSpinner,setIsModalVisible,token,projectDetails,values))
            projectDetails.name = values.name
    }
  return (
    <Modal title="Edit Project Name" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
      <Form form={form} layout="vertical" name="edit_project_name">
        <Form.Item
          name="name"
          label="Project Name"
          rules={[{ required: true, message: "Please input the project name!" }]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default RenameProjectButton;
