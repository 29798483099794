export const PRODUCTION = true;
export const LIVE_MODE = true

let CLIENT_ID = '';
let REDIRECT_URI = '';
let DOMAIN_NAME = '';
export const SCOPES = "https://www.googleapis.com/auth/drive.file";

if (PRODUCTION) 
{
  CLIENT_ID = '208924738299-u3sgaampcrmq57qbig09lbnkf6im5ktb.apps.googleusercontent.com';
  REDIRECT_URI = 'https://leads.cybervisionaries.com';
  DOMAIN_NAME = 'https://vcadmin.cybervisionaries.com';
} 
else 
{
  CLIENT_ID = '176116385447-jdmnf9o9jksi141r9iuihqf79eepvi3p.apps.googleusercontent.com';
  REDIRECT_URI = 'http://localhost';
  DOMAIN_NAME = 'http://127.0.0.1:8000';
}

export { CLIENT_ID, REDIRECT_URI, DOMAIN_NAME };
