import React from 'react'
import styles from './styles/Title.module.css'
const Title = ({backgroundColor, title}) => {
  return (
    <div className='row m-0 pt-4' style={{backgroundColor:backgroundColor}}>
        <div className="col p-0 ps-3 ps-md-5 ">
            
                
            <p className={`m-0 ${styles.title}`} >{title}</p>
            
        </div>
      
    </div>
  )
}

export default Title
