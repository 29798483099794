
import JSZip from 'jszip'; // Import JSZip library
import { DOMAIN_NAME } from './DomainName';
import downloadiconBlue from '../assets/downloadicon.svg'
import downloadicongrey from '../assets/downloadicongrey.svg'

// Function to validate email format
export const validateEmail = (email) => {
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailPattern.test(email);
};

const compromisedPasswords = [
    "123456", "password", "123456789", "12345", "12345678", "qwerty", "abc123",
    // Add more commonly used or compromised passwords here
  ];
  
  function isRepetitiveOrSequential(password) {
    const repetitivePattern = /(.)\1{3,}/; // Checks for 4 or more repetitive characters
    const sequentialPattern = /(0123|1234|2345|3456|4567|5678|6789|7890|abcd|bcde|cdef|defg|efgh|fghi|ghij|hijk|ijkl|jklm|klmn|lmno|mnop|nopq|opqr|pqrs|qrst|rstu|stuv|tuvw|uvwx|vwxy|wxyz)/i; // Checks for basic sequential patterns
    return repetitivePattern.test(password) || sequentialPattern.test(password);
  }
  
  export function isPasswordValid(password, maxLength = 64) {
    // Check password length
    if (password.length < 8 || password.length > maxLength) {
      return { valid: false, reason: `Password must be between 8 and ${maxLength} characters.` };
    }
  
    // Check if password contains only valid ASCII or Unicode characters
    // const asciiUnicodePattern = /^[\x20-\x7E\u00A0-\uD7FF\uE000-\uFFFD\u10000-\u10FFFF]+$/u;
    // if (!asciiUnicodePattern.test(password)) {
    //   return { valid: false, reason: "Password contains invalid characters." };
    // }
  
    // Check against compromised passwords list
    // if (compromisedPasswords.includes(password.toLowerCase())) {
    //   return { valid: false, reason: "Password is too common or compromised." };
    // }
  
    // Check for repetitive or sequential characters
    // if (isRepetitiveOrSequential(password)) {
    //   return { valid: false, reason: "Password contains repetitive or sequential characters." };
    // }
  
    return { valid: true, reason: "Password is valid." };
  }
// Function to validate if a URL is valid
export const validateLinks = (_, value) => {
    console.log(value);
    
    // Helper function to check if a value is a valid URL
    const isValidURL = (url) => {
        return url.includes('.');
    };

    // If value is an array, validate each element
    if (Array.isArray(value)) {
        const invalidLinks = value.some(link => !isValidURL(link));
        
        if (invalidLinks) {
            return Promise.reject(new Error("Please provide valid URLs for all links."));
        }
    } else {
        // If value is not an array, validate the single value
        if (!isValidURL(value)) {
            return Promise.reject(new Error("Please provide a valid URL."));
        }
    }
    
    return Promise.resolve();
};

  
  // Function to check URL validity without requiring a specific protocol
  const isValidUrlWithoutProtocol = (url) => {
    try {
      // Create a URL object with a dummy base URL to validate relative URLs or URLs without protocols
      const parsedUrl = new URL(url, 'http://example.com'); // Dummy base URL
      return !!parsedUrl.hostname; // Check if hostname is present
    } catch (e) {
      return false;
    }
  };
 
export const downloadVideo = (videoPath, fileName) => {
  fetch(videoPath)
    .then(response => response.blob())
    .then(blob => {
      var a = document.createElement('a');
      a.href = window.URL.createObjectURL(blob);
      a.download = `${fileName}.mp4`;
      a.style.display = 'none';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    })
    .catch(error => console.error('Error downloading video:', error));
};


export const getTagColor = (state) => {
    switch (state) {
      case "Completed":
        return "blue";
      case "Draft":
        return "blue";
      default:
        return "orange";
    }
  };

  export const renderTagContent = (state,onClick) => {
    switch (state) {
      case "Completed":
        return (
          <span onClick={onClick}>
            <img src={downloadiconBlue} alt="" /> Download
          </span>
        );
      case "Draft":
        return <span onClick={onClick} style={{color:"rgba(215, 213, 213, 1)"}}>
         <img src={downloadicongrey} alt="" /> Download
      </span>;
      case "In Progress":
        return "Processing";
      case "Error":
        return "Error";
      default:
        return "";
    }
  };

  export const CONVERT_SCROLLERVIDEOS_TO_URL_AND_NAMES = (scrollerVideos,projectDetails)=>{
    let outputVideoPaths = [];
    let customNames = [];
    for (let video of scrollerVideos) {
      outputVideoPaths.push(`${DOMAIN_NAME}${video.output_video}`);
      outputVideoPaths.push(`${DOMAIN_NAME}${video.output_gif}`);
      customNames.push(`${projectDetails.name}-${projectDetails.created_at.slice(0, 10)}-${video.web_url}.mp4`);
      customNames.push(`${projectDetails.name}-${projectDetails.created_at.slice(0, 10)}-${video.web_url}.gif`);
    }
    return {outputVideoPaths,customNames}
  }

  export async function createFolder(folderName, accessToken) {
    const folderMetadata = {
      name: folderName,
      mimeType: 'application/vnd.google-apps.folder',
    };
  
    const response = await fetch('https://www.googleapis.com/drive/v3/files', {
      method: 'POST',
      headers: new Headers({
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(folderMetadata),
    });
  
    if (!response.ok) {
      throw new Error('Failed to create folder');
    }
  
    const folderData = await response.json();
    return folderData.id; // Return the folder ID
  }