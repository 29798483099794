import React from "react";
import "./styles/FeatureComponent.css";
import testimoniallikeicon from '../assets/testimoniallikeicon.svg'
const FeatureComponent = ({ item }) => {
  return (
    <div className="col-12 col-md-6 col-xl-4 p-0 p-md-3 mt-3">
    <div className="row feature-component-card d-flex flex-column px-3">
      <div className="col-12 d-flex justify-content-between align-items-start">
        <span className="feature-component-stars">
          &#9733;&#9733;&#9733;&#9733;&#9733;
        </span>
        <span className="d-flex align-items-center verified-user-text">
          <img src={testimoniallikeicon} alt="" className="testimoniallikeicon" />
          Verified User
        </span>
      </div>
  
      <div className="col-12 my-2 testimonial-description">{item.description}</div>
      
      <div className="col-12 d-flex align-items-center mt-auto">
        <div className="initial-circle">{item.name.charAt(0)}</div>
        <div className="feature-component-name">{item.name}</div>
      </div>
    </div>
  </div>
  

  );
};

export default FeatureComponent;
