import React, { useState, useRef, useEffect } from 'react';
import { Input, notification } from 'antd';
import { API_VALIDATE_COUPON_CODE, API_VALIDATE_COUPON_CODE_FOR_LOGOUT } from '../api/api_payments';
import { useLocation } from 'react-router-dom';

const CouponCode = ({ isLoggedIn, token, setStripeCouponIds, discountCodeState, setDiscountCodeState, setShowSpinner, getPricingModels }) => {
  const [couponCode, setCouponCode] = useState("");
  const couponInputRef = useRef(null);
  const [api, contextHolder] = notification.useNotification();
  const [percentage_off, setpercentage_off] = useState('');
  const location = useLocation();
  const [discount_name, setdiscount_name] = useState('');

  const { stripe_coupon_id_params, percentage_off_params, discount_name_params } = location.state || {};

  useEffect(() => {
    if (stripe_coupon_id_params) {
      setStripeCouponIds(stripe_coupon_id_params);
      setpercentage_off(percentage_off_params);
      setdiscount_name(discount_name_params);
      setDiscountCodeState(2);
    }
  }, [stripe_coupon_id_params, percentage_off_params, discount_name_params]);

  const validateCoupon = async () => {
    try {
      let response = '';
      if (isLoggedIn) {
        response = await API_VALIDATE_COUPON_CODE(setShowSpinner, token, couponCode);
        if (!response) setDiscountCodeState(3);
        else {
            console.log(response)
          setStripeCouponIds(response?.stripe_coupon_id);
          setpercentage_off(response?.percentage_off);
          setdiscount_name(response?.discount_name);
          setDiscountCodeState(2);
        }
      } else {
        response = await API_VALIDATE_COUPON_CODE_FOR_LOGOUT(setShowSpinner, couponCode);
        if (!response) setDiscountCodeState(3);
        else {
            console.log(response)
          setStripeCouponIds(response?.stripe_coupon_id);
          setpercentage_off(response?.percentage_off);
          setdiscount_name(response?.discount_name);
          setDiscountCodeState(2);
        }
      }
      getPricingModels();
    } catch (error) {
      console.error("Error validating coupon code:", error);
    }
  };

  useEffect(() => {
    if (discountCodeState === 1 && couponInputRef.current) {
      couponInputRef.current.focus();
    }
  }, [discountCodeState]);

  useEffect(() => {
    if (discountCodeState === 3) {
      api.info({
        description: "Oops! The discount code could not be applied. Please check if the code is valid, hasn't expired, and meets the conditions. If you need further assistance, please contact customer support",
        placement: "topRight",
      });
      setDiscountCodeState(1);
    }
  }, [discountCodeState]);

  return (
    <>
      {contextHolder}
      {discountCodeState === 0 && (
        <div
          className="col-12 have-a-discount-code ps-5"
          style={{ color: `${isLoggedIn ? "rgba(0, 126, 178, 1)" : 'white'}`, textAlign: `${isLoggedIn ? 'start' : 'center'}` }}
          onClick={() => setDiscountCodeState(1)}
        >
          Have a discount code?
        </div>
      )}
      {discountCodeState === 1 && (
        <>
          <div className="row m-0  pe-xxl-5">
            <div className="col-12 col-md-8 coupon-input px-0">
              <Input
                onChange={(e) => setCouponCode(e.target.value)}
                placeholder="Enter discount code"
                value={couponCode}
                ref={couponInputRef}
              />
            </div>
            <div className="col-12 col-md-4 coupon-input-btn px-0 ps-0 ps-md-2">
              <button onClick={validateCoupon}>Enter discount code</button>
            </div>
          </div>
        </>
      )}
      {discountCodeState === 2 && (
        <div className="col-12"
          style={{ color: `${isLoggedIn ? "rgba(0, 126, 178, 1)" : 'white'}`, textAlign: `${isLoggedIn ? 'start' : 'center'}` }}
          onClick={() => setDiscountCodeState(1)}
        >
          <span className="discount-code-applied" style={{ color: `${isLoggedIn ? "rgba(0, 126, 178, 1)" : 'white'}` }}>Discount code applied: </span>
          <span className="discount-code-applied-yellow">{couponCode?.toUpperCase()} (-{percentage_off}% {discount_name?.toUpperCase()})</span>
        </div>
      )}
    </>
  );
};

export default CouponCode;
