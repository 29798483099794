export const ENVOKE_USER_REGISTERED_EVENT = () => {
    if (window.gtag) {
      window.gtag('event', 'user_registered', {
        'event_category': 'engagement',
        'event_label': 'User Registered',
        'value': 1
      });
    }
  }
  export const ENVOKE_PURCHASE_EVENT = () => {
    if (window.gtag) {
      window.gtag('event', 'purchase', {
        'event_category': 'ecommerce',
        'event_label': 'Purchase Completed',
        'value': 1
      });
    }
  }
  export const ENVOKE_TRACK_CHECKOUT_PAGE_VISIT = () => {
    if (window.gtag) {
      window.gtag('event', 'conversion_checkout', {
        'event_category': 'engagement',
        'event_label': 'Conversion Checkout',
        'value': 1
      });
    }
  }
      