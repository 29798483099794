import axios from "axios";
import { DOMAIN_NAME } from "../values/DomainName";
import { message } from "antd";
import { ENVOKE_PURCHASE_EVENT, ENVOKE_TRACK_CHECKOUT_PAGE_VISIT } from "../values/Analytics";

export const API_GET_PRICING_MODELS = async(setShowSpinner,token,isLoggedIn,stripe_coupon_id, monthlyModeActive)=>{
    
    setShowSpinner(true)
    try {
      const response = isLoggedIn
        ? await axios.get(
            `${DOMAIN_NAME}/payments/get_pricing_model_for_user/`, //shows for logged in user
            {
              params: {
                coupon_id: stripe_coupon_id,
                is_yearly: !monthlyModeActive,
              },
              headers: {
                Authorization: token,
              },
            }
          )
        : await axios.get(
            `${DOMAIN_NAME}/payments/get_pricing_model_for_all_users/`,//shows for logged out user
            {
              params: {
                coupon_id: stripe_coupon_id,
                is_yearly: !monthlyModeActive,
              },
            }
          );
      console.log(response.data);
      return response.data;
    } catch (error) {
      message.error("Failed to fetch pricing models.");
      console.error(error);
    } finally {
      setShowSpinner(false);
    }
}


export const API_CREATE_CHECKOUT_SESSION = async(setShowSpinner,token,priceId,paymentType,stripeCouponId,quantity)=>{
    setShowSpinner(true)
    ENVOKE_TRACK_CHECKOUT_PAGE_VISIT()
    console.log('ENVOKE_TRACK_CHECKOUT_PAGE_VISIT')
    try {
        const response = await axios.post(
            `${DOMAIN_NAME}/payments/create_checkout_session/`,
            {
                price_id: priceId,
                payment_type: paymentType,
                stripe_coupon_id: stripeCouponId,
                quantity: quantity
              },
            {
              headers: {
                Authorization: token,
                "Content-Type": "application/json",
              },
            }
        )
        if (response.data.url) {
            window.location.href = response.data.url; // Redirect to Stripe checkout page
            
        }
        
      } catch (error) {
        message.error("Failed to fetch pricing models.");
        console.error(error);
      } finally {
        setShowSpinner(false);
      }
}



export const API_VALIDATE_COUPON_CODE_FOR_LOGOUT = async(setShowSpinner,couponCode)=>{
    console.log(couponCode)
    setShowSpinner(true)
    try {
        const response = await axios.post(
            `${DOMAIN_NAME}/marketing/validate_coupon_code_for_logout/`,
            { coupon_code: couponCode },
        )
        console.log('response.data',response.data)
        return response.data
      } catch (error) {
        console.error(error);
        return false
      } finally {
        setShowSpinner(false);
      }
}

export const API_VALIDATE_COUPON_CODE = async(setShowSpinner,token,couponCode)=>{
    console.log(couponCode)
    setShowSpinner(true)
    try {
        const response = await axios.post(
            `${DOMAIN_NAME}/marketing/validate_coupon_code/`,
            { coupon_code: couponCode },
            {
              headers: {
                Authorization: token,
                "Content-Type": "application/json",
              },
            }
        )
        return response.data
      } catch (error) {
        console.error(error);
        return false
      } finally {
        setShowSpinner(false);
      }
}