import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import axios from "axios";
import { setAuthToken, setLoggedIn } from "../redux/AuthToken/Action";
import styles from "./styles/GoogleLoginBtn.module.css";
import { GoogleLogin } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";
import { DOMAIN_NAME } from "../values/DomainName";

const GoogleLoginBtn = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLogin = async (credentialResponseDecoded) => {
    try {
     
      const [firstName, lastName] = credentialResponseDecoded.name.split(" ");

      const response = await axios.post(
        `${DOMAIN_NAME}/auth/social_signin/`,
        {
          email: credentialResponseDecoded.email,
          first_name: firstName,
          last_name: lastName,
        }
      );

      dispatch(setAuthToken(response.data.token));
      dispatch(setLoggedIn(true));
      
      navigate('/projects')
    } catch (error) {
      let errorMessage = "An unknown error occurred";
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        errorMessage = error.response.data.message;
      }
    }
  };

  return (
    <div className={`${styles.googleLoginWrapper} mb-3`}>
      <GoogleLogin
        onSuccess={(credentialResponse) => {
            const credentialResponseDecoded = jwtDecode(credentialResponse.credential);
          handleLogin(credentialResponseDecoded)
        }}
        onError={() => {
          console.log("Login Failed");
        }}
      />
      
    </div>
  );
};

export default GoogleLoginBtn;
