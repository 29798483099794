import React, { useState } from 'react';
import { Button, Slider } from 'antd';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';

const CustomQuantitySelector = ({ min = 1, max = 100, onChange, text, step = 1 ,value }) => {
  const [quantity, setQuantity] = useState(value);

  const roundToTwoDecimalPlaces = (value) => parseFloat(value.toFixed(2));

  const increase = () => {
    if (quantity < max) {
      const newQuantity = roundToTwoDecimalPlaces(quantity + step);
      setQuantity(newQuantity);
      onChange(newQuantity);
    }
  };

  const decrease = () => {
    if (quantity > min) {
      const newQuantity = roundToTwoDecimalPlaces(quantity - step);
      setQuantity(newQuantity);
      onChange(newQuantity);
    }
  };

  const handleSliderChange = (value) => {
    const roundedValue = roundToTwoDecimalPlaces(value);
    setQuantity(roundedValue);
    onChange(roundedValue);
  };

  return (
    <div className="row">
      <div className="col-4">
        <p style={{ color: "black", fontWeight: "600", margin: '0px' }}>{text}</p>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Button
            icon={<MinusOutlined />}
            onClick={decrease}
            disabled={quantity <= min}
            style={{ marginRight: 8, backgroundColor: "transparent", border: "none" }}
          />
          <span style={{ marginRight: 8 }}>{quantity}</span>
          <Button
            icon={<PlusOutlined />}
            onClick={increase}
            disabled={quantity >= max}
            style={{ backgroundColor: "transparent", border: "none" }}
          />
        </div>
      </div>
      <div className="col-8 align-self-end">
        <Slider
          min={min}
          max={max}
          step={step}
          onChange={handleSliderChange}
          value={quantity}
        />
      </div>
    </div>
  );
};

export default CustomQuantitySelector;
