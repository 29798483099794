import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import "./styles/Pricing.css";
import Navbar from "../components/Navbar";
import axios from "axios";
import Title from "../components/Title";
import { Spin, Switch } from "antd";
import { Helmet } from "react-helmet";
import pricing1_img from "../assets/pricing1.svg";
import pricing2_img from "../assets/pricing2.svg";
import pricing3_img from "../assets/pricing3.svg";
import { FAQ } from "../lessUse/HomePageComponents";
import { GET_VIDEOS_LEFT } from "../api/api_settings";
import { API_CREATE_CHECKOUT_SESSION, API_GET_PRICING_MODELS } from "../api/api_payments";
import CouponCode from "../lessUse/CouponCode";
import Footer from "../components/Footer";
import { DOMAIN_NAME } from "../values/DomainName";

const Pricing = () => {
  const navigate = useNavigate();
  const [sessionId, setSessionId] = useState("");
  const location = useLocation();
  const [success, setSuccess] = useState(false);
  const [PricingModels, setPricingModels] = useState([]);
  const [showSpinner, setShowSpinner] = useState(false);
  const { token, isLoggedIn,referrerUrl  } = useSelector((state) => state.authToken);
  const [remaining_tokens, setremaining_tokens] = useState(0);
  const [stripeCouponIds, setStripeCouponIds] = useState({});
  const [is_any_of_monthly_active, setis_any_of_monthly_active] = useState(false);
  const [is_any_of_yearly_active, setis_any_of_yearly_active] = useState(false);
  const [discountCodeState, setdiscountCodeState] = useState(0);
  const [monthlyModeActive, setmonthlyModeActive] = useState(true);
  const [RefererAlreadyCalled, setRefererAlreadyCalled] = useState(false);

  const getPricingModels = async () => {
    const response = await API_GET_PRICING_MODELS(setShowSpinner, token, isLoggedIn, stripeCouponIds,monthlyModeActive);
    setPricingModels(response);
    if(monthlyModeActive)
    {
        if (response?.some((item) => item.is_active_model == true)) {
          setis_any_of_monthly_active(true);
        } else {
            setis_any_of_monthly_active(false);
        }

    }
    else{
        if (response?.some((item) => item.is_active_model == true)) {
            setis_any_of_yearly_active(true);
          } else {
              setis_any_of_yearly_active(false);
          }
    }
    console.log("RESPONSE = ",stripeCouponIds,response)
  };


  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    getPricingModels();
    
    
    const query = new URLSearchParams(location.search);
    const pid = query.get("priceId");
    const pt = query.get("paymentType");
    const scid = query.get("stripeCouponIds");
    if(pid)
    {
        handleCheckout(pid, pt, scid)
    }
    

  }, [stripeCouponIds,monthlyModeActive]);


  const handleCheckout = async (priceId, paymentType, stripeCouponId) => {
    if (!priceId) return;
    await API_CREATE_CHECKOUT_SESSION(setShowSpinner, token, priceId, paymentType, stripeCouponId,1);
  };

  
  const handlePlanClick = async (priceId, paymentType) => {
    if (!isLoggedIn) {
        const queryParams = new URLSearchParams({
            next: '/pricing',
            checkout: 'true',
            priceId: priceId,
            paymentType: paymentType,
            stripeCouponId: stripeCouponIds,
            showSignup:true
            
        }).toString();

        // Navigate with the constructed URL
        navigate(`/account?${queryParams}`);
    } else {
      handleCheckout(priceId, paymentType, stripeCouponIds);
    }
  };
  const trackPurchaseUrl = async (referrerUrl) => {
    console.log(token)
    try {
      const response = await axios.post(
        `${DOMAIN_NAME}/analytics/track_purchase_url/`,
        {
          url: referrerUrl, // Data goes here
        },
        {
          headers: {
            Authorization: token, // Ensure proper format for Authorization header
            "Content-Type": "application/json",
          },
        }
      );
      console.log('API Response:', response.data);
    } catch (error) {
      console.error('Error calling API:', error);
    }
  };
  
  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    if (query.get("success")) {
      setSessionId(query.get("session_id"));
      setSuccess(true);
    }
    if (query.get("canceled")) {
      setSuccess(false);
    }
  }, [sessionId]);

  useEffect(()=>{
    const query = new URLSearchParams(window.location.search);
    if (query.get("success") && !RefererAlreadyCalled) {
        trackPurchaseUrl(referrerUrl)
        setRefererAlreadyCalled(true)
      }
  },[])
  const getRemainingVideos = async () => {
      const response = await GET_VIDEOS_LEFT(setShowSpinner, token);
      setremaining_tokens(response?.videos_left);
    
  };

  useEffect(() => {
    if(isLoggedIn) getRemainingVideos();
  }, [remaining_tokens]);


  const handleAddMoreToken = async()=>{
    await API_CREATE_CHECKOUT_SESSION(setShowSpinner,token,'price_1PtvsnCoUIh1QMeBphretg7M',"one_time","",5)
  }
  return (
    <>
    <Helmet>
      <title>Pricing | Cyber Visionaries</title>
    </Helmet>
      {!isLoggedIn && <Navbar />}
      {showSpinner && <Spin size="large" style={{ position: "fixed", top: "50%", left: "50%" }} />}
     {isLoggedIn &&  <Title title="PLAN MANAGER" />}
      {isLoggedIn && (
        <div className="row px-3 pe-xxl-1 py-3 ps-xxl-5 p-0 m-0" style={{margin:"0px 0px 30px 0px"}}>
            <div className={`col-12 p-0 justify-content-evenly px-md-3 ${window.innerWidth > 1400 && window.innerWidth < 1500 ? "col-xxl-6":"col-xxl-5"
            }`}>
                <div className="row m-0">
                    <div className="col-12 col-lg-6 p-0">
                        <button className="remaining-tokens ">
                        AVAILABLE TOKENS: <span className="available-token-number">{remaining_tokens}</span>
                        </button>
                    </div>
                    <div className="col-12 col-lg-6 ps-0 ps-lg-2 mt-2 mt-lg-0 p-0">
                            <button className="add-more-tokens m-0" onClick={handleAddMoreToken}>
                            + GET MORE TOKENS
                        </button>
                    </div>
                </div>
                
            </div>

            <div className={`col-12 justify-content-end text-start p-0 align-self-center px-md-3 mt-2   mt-xxl-0  ${window.innerWidth > 1400 && window.innerWidth < 1500 ? "col-xxl-6":"col-xxl-7"}`}>
                <CouponCode 
                    isLoggedIn={isLoggedIn}
                    token={token}
                    setStripeCouponIds={setStripeCouponIds}
                    discountCodeState={discountCodeState}
                    setDiscountCodeState={setdiscountCodeState}
                    setShowSpinner={setShowSpinner}
                />
            </div>
      </div>
      
      )}

      <div className="row m-0 pricing-main justify-content-center px-3 pe-xxl-5 ps-xxl-5">
        <div className="col-12 px-1 px-md-3 pricing-main-inner">
            <div className="row m-0 justify-content-center">
            <div className="col-11 col-sm-12 text-center ">
          <span className="beta-pricing">
            <p className="beta-pricing-text">BETA PRICING</p>
          </span>
          <p className="transparent-pricing-text">Simple, transparent pricing</p>
          <p className="transparent-pricing-text-secondary">
            We believe automation should be accessible to all companies, no matter the size.
          </p>
        </div>
        <div className="col-12 pricing-container justify-content-center mb-4">
            <span className={`pricing-label ${monthlyModeActive && "is-label-active"}`} >Monthly Pricing</span>
            <Switch className="pricing-checkbox"  onChange={(checked) => setmonthlyModeActive(!checked)} checked={!monthlyModeActive}/>
            <span className={`pricing-label ${!monthlyModeActive && "is-label-active"}`}>Yearly Pricing</span>
            <span className={`discount-note ${!monthlyModeActive && "is-discount-note-active"}`}>(save 20%)</span>
        </div>

        <div className="col-12 col-lg-10">
          <div className="row justify-content-around">
            <div className="col-12 col-lg-4 px-2">
              <div className="row m-0">
                <div className="col-12 px-4 pricing-card">
                  <img src={pricing2_img} alt="Product Image" />
                  <p className="pricing-name">Basic</p>
                  <p className="heading-text">Free</p>

                  <div className="info-container">
                    <div className="info-item">
                      <i className="icon-class fa-solid fa-check"></i> <p className="info-text">100 prospecting videos / month</p>
                    </div>
                    <div className="info-item">
                      <i className="icon-class fa-solid fa-check"></i> <p className="info-text">Additional videos: $0.22 / video (min. 5 tokens / order)</p>
                    </div>
                  </div>
                  {(!(is_any_of_monthly_active || is_any_of_yearly_active) && isLoggedIn) ? <button className="active-button">Active</button> : 
                  <button className="get-started-button" onClick={() => {
                    if(isLoggedIn)
                        {
                            handleCheckout(null, "basic", null)
                        } 
                        else
                        {
                            const queryParams = new URLSearchParams({
                                next: '/pricing',
                                showSignup:true
                                
                            }).toString();
                            navigate(`/account?${queryParams}`);
                        }
                  }}>
                    Get Started
                  </button>
                  }
                </div>
              </div>
            </div>

            {PricingModels?.map((item,index) => {
console.log(item)
                return(
              <div className="col-12 col-lg-4 px-2" key={item.stripe_price_id}>
                <div className="row m-0">
                  <div className="col-12 px-4 pricing-card">
                    <img src={index == 0 ? pricing3_img : pricing1_img} alt="Product Image" />
                    <p className="pricing-name">{item.name}</p>
                    {!item.is_active_model && (
                      monthlyModeActive ? 
                      <p className="pricing-amount">
                        {item.discounted_price ? (
                            <>
                                <span className="discounted-price align-self-center">${+item.price}</span>
                                <span>${+item.discounted_price}</span>
                            </>
                        ) : (
                            <>
                                <span className="discounted-price align-self-center">${index == 0 ? "74" : "194"}</span>
                                <span>${+item.price}</span>
                            </>
                        )}
                    </p>
                    :
                    <p className="pricing-amount">
                        {item.discounted_price ? (
                          <>
                            <span className="discounted-price align-self-center">${+item.price}</span>
                            <span>${+item.discounted_price}</span>
                          </>
                        ) : (
                          <>
                            <span className="discounted-price align-self-center">${index == 0 ? "888" : "2328"}</span>
                            <span>${+item.price}</span>

                            </>
                        )}
                      </p>
                    )}
                    {/* {!item.discounted_price && <p className="beta-pricing-text"> BETA  PRICING - 50% DISCOUNT </p>} */}
                    {!item.is_active_model && <p className="beta-pricing-text"> BETA PRICING - 50% OFF </p>}
                    <p className="heading-text-2">Billed {monthlyModeActive ? "Monthly" : "Yearly"}</p>

                    <div className="info-container">
                      {item.description.split("\n").map((sentence, idx) => (
                        <div className="info-item" key={idx}>
                          <i className="icon-class fa-solid fa-check"></i> <p className="info-text">{sentence}</p>
                        </div>
                      ))}
                    </div>

                    {item.is_active_model ? (
                      <button className="active-button">Active</button>
                    ) : (
                        
                      <button className="get-started-button" 
                      
                      onClick={() => handlePlanClick(item.stripe_price_id, item.payment_type)}>
                        Get Started
                      </button>
                    )}
                  </div>
                </div>
              </div>)
            })}
            {!isLoggedIn &&<>
             
                <CouponCode  isLoggedIn={isLoggedIn} token={token} setStripeCouponIds={setStripeCouponIds} discountCodeState={discountCodeState} setDiscountCodeState={setdiscountCodeState} setShowSpinner={setShowSpinner} getPricingModels={getPricingModels}/>
             
             <span className="mb-3"></span></>
             }
            
          </div>
        </div>
            </div>
        </div>
      </div>

      <div className="row m-0 justify-content-center p-0">
        <div className="col-12 col-sm-12 pb-4 px-3 px-xxl-5" id={'pricing_faq'}>
          <FAQ show="pricing" isLoggedIn={isLoggedIn} navigate={navigate}/>
        </div>
      </div>
      {!isLoggedIn && <div className={`row m-0 px-2 py-3 p-lg-4 ${"footer_component"}`}>
        <Footer />
      </div>}
    </>
  );
};

export default Pricing;
