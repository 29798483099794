import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Button, Form, Input, message, Spin } from 'antd';
import { LockOutlined } from "@ant-design/icons";
import axios from 'axios';
import Navbar from '../components/Navbar';
import styles from './styles/ResetPassword.module.css';
import { useDispatch } from "react-redux";
import { removeAuthToken, setLoggedIn } from '../redux/AuthToken/Action';
import { passwordRules } from '../values/AuthHelpers';
import { DOMAIN_NAME } from '../values/DomainName';
import { API_SET_NEW_PASSWORD } from '../api/api_authentication';

const ResetPassword = () => {
  const { email } = useParams();
  const navigate = useNavigate();
  const [newPassword, setNewPassword] = useState('');
  const dispatch = useDispatch();
  const [confirmedPassword, setConfirmedPassword] = useState('');
  const [showSpinner, setShowSpinner] = useState(false);

  const handleNewPasswordChange = (e) => {
    setNewPassword(e.target.value);
  };

  const handleConfirmedPasswordChange = (e) => {
    setConfirmedPassword(e.target.value);
  };

  const handleNewPasswordSubmit = async () => {
    if (newPassword === confirmedPassword) {
      await API_SET_NEW_PASSWORD(setShowSpinner,email,newPassword,navigate)
    } else {
      message.error("Passwords don't match");
    }
  };

  return (
    <>
      
      <Navbar />
      {showSpinner && <Spin fullscreen/>}
      <div className={`row m-0 justify-content-center ${styles.mainRow}`}>
        <div className={`col-12 col-xl-6 col-lg-8 col-md-9 col-sm-10 p-0 align-self-center ${styles.mainCol} text-center`}>
          <p className={styles.createAccountHeading}>Reset Password</p>
          <div className="row justify-content-center">
            <div className="col-10">
              <Form onFinish={handleNewPasswordSubmit} className={styles.loginForm} validateTrigger={['onBlur']}>
                <Form.Item name="newPassword" rules={passwordRules}>
                  <Input.Password  prefix={<LockOutlined />}  onChange={handleNewPasswordChange}  className={styles.loginInput1}  placeholder="Enter new Password..." />
                </Form.Item>
                <Form.Item name="confirmedPassword" rules={passwordRules}>
                  <Input.Password  prefix={<LockOutlined />}  onChange={handleConfirmedPasswordChange}  className={styles.loginInput1}  placeholder="Confirm Password..." />
                </Form.Item>
                <Button type="primary" htmlType="submit" className={styles.submitButton}>Confirm Password</Button>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
