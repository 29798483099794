import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import Title from "../components/Title";
import { Spin, message, Button, Dropdown, Menu, Divider } from "antd";
import SingleProjectCard from "../components/SingleProjectCard";
import AddNewProject from "../components/AddNewProject";
import { useSelector } from "react-redux";
import { DatePicker, Space } from "antd";
import styles from "./styles/Projects.module.css";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { SortAscendingOutlined } from "@ant-design/icons"; // Import the custom icon
import { API_FETCH_PROJECTS } from "../api/api_project";
import sorticon from "../assets/sorticon.svg";
import { Helmet } from "react-helmet";

const { RangePicker } = DatePicker;

const Projects = () => {
  const token = useSelector((state) => state.authToken.token);
  const [ShowSpinner, setShowSpinner] = useState(false);
  const [projects, setProjects] = useState([]);
  const navigate = useNavigate();
  const [Rerenderer, setRerenderer] = useState(false);
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [sortOrder, setSortOrder] = useState("newest");
  const [PageTitle, setPageTitle] = useState("Projects | Cyber Visionaries");
  const [isDropDownOpen, setisDropDownOpen] = useState(false);
  const disabledDate = (current) => {
    const fourteenDaysAgo = moment().subtract(14, "days");
    return current && (current < fourteenDaysAgo || current > moment());
  };

  const refreshFlag = useSelector((state) => state.refreshFlag);

  const fetchProjects = async () => {
    const sortedProjects = await API_FETCH_PROJECTS(setShowSpinner, token);
    setProjects(sortedProjects);
    setFilteredProjects(sortedProjects);
  };

  useEffect(() => {
    fetchProjects();
  }, [refreshFlag]);

  useEffect(() => {
    fetchProjects();
  }, [token, Rerenderer]);

  const flipRerenderer = () => {
    const renderValue = Rerenderer;
    setRerenderer(!renderValue);
  };

  const filterProjectsByDateRange = (dateStrings) => {
    const [startDate, endDate] = dateStrings;
    if (!startDate && !endDate) {
      setFilteredProjects(projects);
      return;
    }
    const startMoment = moment(startDate, "YYYY-MM-DD").startOf("day");
    const endMoment = endDate
      ? moment(endDate, "YYYY-MM-DD").endOf("day")
      : moment().endOf("day");

    const filteredProjects = projects.filter((project) => {
      const createdAtMoment = moment(project.created_at).startOf("day");
      return createdAtMoment.isBetween(startMoment, endMoment, null, "[]");
    });

    setFilteredProjects(filteredProjects);
  };

  const onDateChange = (dates, dateStrings) => {
    if (dateStrings[0] && dateStrings[1] && dateStrings[1] < dateStrings[0]) {
      message.error("End date cannot be less than start date.");
      return;
    }
    filterProjectsByDateRange(dateStrings);
  };

  const sortProjects = (order) => {
    let sortedProjects = [...filteredProjects];
    setisDropDownOpen(false);
    if (order === "newest") {
      sortedProjects.sort((a, b) =>
        moment(b.created_at).diff(moment(a.created_at))
      );
    } else {
      sortedProjects.sort((a, b) =>
        moment(a.created_at).diff(moment(b.created_at))
      );
    }
    setFilteredProjects(sortedProjects);
  };

  const handleSortChange = ({ key }) => {
    setSortOrder(key);
    sortProjects(key);
  };

  return (
    <>
    <Helmet>
      <title>{PageTitle}</title>
    </Helmet>
      <div className="row m-0 ">
        <div className="col-12 p-0 ">
          <div className="row m-0">
            <div className="col-12 col-md-4">
            <div className='row pt-4 m-0'>
                <div className="col p-0  ps-3">
                    
                        
                    <p className={`m-0 ${styles.title}`} >MY CAMPAIGNS</p>
                    
                </div>
            
            </div>
            </div>
            <div className="col-12 col-md-8  pt-3 ms-sm-0 text-end">
          <div className={styles.filter}>
            <div className="row px-4 px-xxl-0 z-3">
              {/* Sort By Dropdown */}
              {window.innerWidth <= 1350 && <div className={`col-12 col-xl-6 p-0 ps-xxl-2 ${styles.btn_filled_parent}`}>
                <button className={styles.btn_filled} onClick={() => navigate("/newProject")} >
                  &#43; New Campaign
                </button>
              </div>}
              <div className={`col-12 col-xl-6 mb-2 mb-md-0 m-0 p-0  ${styles.sort_dropdown}`} >
                <p onClick={() => setisDropDownOpen(!isDropDownOpen)} style={{ borderBottom: `${ isDropDownOpen ? "1px solid rgba(0, 126, 178, 1)" : "none" }`, }} > <img src={sorticon} className="me-3" /> SORT BY</p>

                {isDropDownOpen && (
                  <>
                    <p onClick={() => sortProjects("newest")} className="text-start ms-4" > Newest </p>
                    <div className={styles.divider}></div>
                    <p onClick={() => sortProjects("oldest")} className="text-start ms-4" > Oldest </p>
                  </>
                )}
              </div>

              {/* New Campaign Button */}
              {window.innerWidth > 1350 && <div className={`col-12 col-xl-6 p-0 ps-xxl-2 ${styles.btn_filled_parent}`}>
                <button className={styles.btn_filled} onClick={() => navigate("/newProject")} >
                  &#43; New Campaign
                </button>
              </div>}
            </div>
          </div>
            </div>
            {ShowSpinner && <Spin fullscreen />}
            <div className={`row mb-4  m-0 justify-content-center justify-content-sm-start ${styles.projects_row}`} >
            <AddNewProject />
            {filteredProjects?.map((project) => (
                <SingleProjectCard
                key={project.id}
                projectDetails={project}
                flipRerenderer={flipRerenderer}
                />
            ))}
            </div>

          </div>
        </div>
        {/* Title Section */}

        {/* Buttons Section */}
      </div>

      
    </>
  );
};

export default Projects;
