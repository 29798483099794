import React, { useEffect, useState } from "react";
import { Progress, Popconfirm, message,Tag, Button } from "antd";
import { CompressOutlined, DeleteOutlined,DownloadOutlined } from "@ant-design/icons";
import styles from './styles/ProjectLinkCard.module.css';
import { downloadVideo, getTagColor, renderTagContent } from "../values/Helpers";
import { DOMAIN_NAME } from "../values/DomainName";

const ProjectLinkCard = ({ videoLink, index, onDelete,projectState, created_at,project_name}) => {
    const [ProgressValue, setProgressValue] = useState(30);
    const [StrokeColor, setStrokeColor] = useState("#52c41a");

    const confirmDelete = () => {
        onDelete(videoLink.id);
    };
    return (
        <div className={`row justify-content-center ${styles.cardContainer}`} id={`project-links-${videoLink.id}`}>
             <div className={`col-12`}>
             <Tag style={{
                color:`${videoLink.state=="Completed" ? "rgba(0, 126, 178, 1)" : videoLink.state == "Draft" ? "rgba(144, 144, 144, 1)":videoLink.state=="In Progress" && "rgba(255, 152, 30, 1)"}`,
                borderColor: `${videoLink.state=="Completed" ? "rgba(0, 126, 178, 1)" : videoLink.state == "Draft" ? "rgba(144, 144, 144, 1)":videoLink.state=="In Progress" && "rgba(255, 152, 30, 1)"}`,
                marginRight:"5px", 
                borderRadius:"10px"}}>
                {renderTagContent(videoLink.state,()=>{ downloadVideo(`${DOMAIN_NAME}${videoLink.output_video}`,`${project_name}-${created_at}-${videoLink.web_url}`)})}
            </Tag>
            <span className={styles.index}> {videoLink.web_url.length > 35 ? `${videoLink.web_url.substring(0, 35)}...` : videoLink.web_url} </span>
            </div>
            {/* <div className={`col-12 col-md-11 d-flex align-items-center ${styles.progress} text-start`}>
                {ProgressValue > 0 && <Progress percent={videoLink.progress} size="small" strokeColor={StrokeColor} trailColor="#ddd" showInfo={false} />}
                {videoLink.state == "Completed" && <DownloadOutlined onClick={()=>downloadVideo(`${DOMAIN_NAME}${videoLink.output_video}`,videoLink.web_url.substring(8, 38))}className={styles.icon}/>}
                <Popconfirm
                    title="Are you sure to delete this link?"
                    onConfirm={confirmDelete}
                    okText="Yes"
                    cancelText="No"
                >
                    {projectState == "Draft" &&<DeleteOutlined className={styles.icon} />}
                </Popconfirm>
            </div> */}
        </div>
    );
};

export default ProjectLinkCard;
