// Affiliate.js

import React, { useEffect } from 'react';
import axios from 'axios';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { DOMAIN_NAME } from '../values/DomainName';
import { useSelector } from 'react-redux';
import { message, Spin, Modal, Input, Button } from "antd";
import { useNavigate } from 'react-router-dom';

const Affiliate = () => {
  const { affiliateName } = useParams();
  const { token, isLoggedIn } = useSelector((state) => state.authToken);
  const [showSpinner, setShowSpinner] = useState(false);
  const navigate = useNavigate()

  useEffect(() => {
    if (affiliateName) {
      const fetchAffiliateData = async () => {
        setShowSpinner(true)
        try {
          const response = await axios.post(
            `${DOMAIN_NAME}/marketing/validate_affiliate_prefix/`,
            {
              affiliate_prefix: affiliateName,
            },
            {
              headers: {
                Authorization: token,
                'Content-Type': 'application/json',
              },
            }
          );

          console.log('API Response:', response.data);
          message.success("Discount availed successfully!")
          navigate('/pricing', { state: { stripe_coupon_id_params: response.data.stripe_coupon_id,percentage_off_params:response.data.percentage_off,discount_name_params:response.data.discount_name } });
          
        } catch (error) {
          console.error('Error:', error);
          navigate('/notFound')
        }
        setShowSpinner(false)
      };

      if(isLoggedIn)
      {fetchAffiliateData();}
      else
      {
        navigate(`/account?next=${encodeURIComponent(window.location.pathname)}`);
      }
    }
  }, [affiliateName]);

  return (
    <div className='text-white'>
      {showSpinner && <Spin fullscreen />}
    </div>
  );
};

export default Affiliate;
