import React, { useEffect, useState } from "react";
import { Button, Form, Input, Select, Upload, message, Spin } from "antd";
import { UploadOutlined, CloseCircleOutlined } from "@ant-design/icons";
import Papa from "papaparse";
import styles from "./styles/NewProject.module.css";
import { validateLinks } from "../values/Helpers";
import { VIDEO_LINK_LIMIT } from "../values/settings";
import { GET_VIDEOS_LEFT } from "../api/api_settings";
import { useSelector } from "react-redux";

const { Option } = Select;

const WebsiteInfoForm = ({ initialValues, handleChange, normFile1, onFirstFormFinish, setIsFileUploaded, IsFileUploaded }) => {
  const [fileList1, setFileList1] = useState([]);
  const [links, setLinks] = useState(initialValues?.links || []);
  const [selectKey, setSelectKey] = useState(0); // Key for Select component
  const [showSpinner, setShowSpinner] = useState(false);
  const [remaining_tokens, setremaining_tokens] = useState(0);
  const { token, isLoggedIn } = useSelector((state) => state.authToken);

  // Get form instance
  const [form] = Form.useForm();

  
  // Custom validator to check the number of links
  const validateLinkCount = (_, value) => {
    if (value && value.length > remaining_tokens) {
      return Promise.reject(new Error(`You can only enter up to ${remaining_tokens} links.`));
    }
    return Promise.resolve();
  };

  const getRemainingVideos = async () => {
    const response = await GET_VIDEOS_LEFT(setShowSpinner, token);
    setremaining_tokens(response?.videos_left);
  };

  useEffect(() => {
    getRemainingVideos();
  }, []);

  // Handle CSV upload and convert its content to options
  const handleCSVUpload = ({ fileList }) => {
    if (fileList.length > 0) {
      setShowSpinner(true); // Set loading to true when file upload starts
      const file = fileList[0];
      Papa.parse(file.originFileObj, {
        complete: (result) => {
          const csvLinks = result.data
            .flat()
            .filter((link) => link); // Flatten array and filter out empty strings
          if (csvLinks.length > VIDEO_LINK_LIMIT) {
            message.error(`You can only upload up to ${VIDEO_LINK_LIMIT} links!`);
            setShowSpinner(false); // Set loading to false if there's an error
          } else {
            setLinks(csvLinks); // Set links directly from CSV file, replacing existing links
            handleChange(csvLinks);
            setFileList1(fileList);
            setIsFileUploaded(true);
            setShowSpinner(false); // Set loading to false when upload is complete
          }
        },
        error: (error) => {
          message.error("Failed to parse CSV file!");
          console.error(error);
          setShowSpinner(false); // Set loading to false if there's an error
        }
      });
    } else {
      setFileList1([]);
      setIsFileUploaded(false); // Set fileUploaded to false
    }
  };

  // Validator to ensure at least one link is provided either through manual input or CSV upload
  const validateForm = () => {
    if (links.length === 0) {
      message.error('Please provide at least one link either by uploading a CSV file or entering links manually.');
      return false;
    }
    return true;
  };

  const onFinish = async (values) => {
    if (validateForm()) {
      const formValuesWithLinks = {
        ...values,
        links: links // Include links in form values
      };
      console.log("Form values:", formValuesWithLinks); // Debugging statement
      onFirstFormFinish(formValuesWithLinks);
    }
  };

  // Clear links and reset form fields
  const clearLinks = () => {
    setLinks([]);
    setSelectKey(prevKey => prevKey + 1); // Resetting the Select component's key to force re-render
    form.resetFields(['links']); // Reset the "links" field to remove any errors
  };
  const clearFileUpload = () => {
    setFileList1([]);
    setIsFileUploaded(false);
    setSelectKey(prevKey => prevKey + 1); // This is to reset the Select component as well
  };
  return (
    <div className="row justify-content-center m-0">
      {showSpinner && <Spin fullscreen />}
      <div className={`col-10 text-start mb-4 ${styles.WebsiteInfoFormCol}`}>
        <p className={styles.step}>Step 1: Upload Leads</p>

        <Form
          form={form} // Attach form instance
          name="trigger"
          layout="vertical"
          autoComplete="off"
          onFinish={onFinish}
          initialValues={initialValues}
          validateTrigger="onBlur"
        >
          <p className={styles.inputTitles}>Campaign Name</p>
          <Form.Item
            name="projectName"
            validateTrigger="onBlur"
            rules={[
              { required: true, message: 'Please enter a project name.' },
              { max: 255, message: 'Project name cannot exceed 255 characters.' }
            ]}
          >
            <Input placeholder="Enter Project Name" className={styles.input} autoFocus />
          </Form.Item>

          <p className={styles.inputTitles}>Enter Website Links URLs (Leads)</p>
          <Form.Item name="dragger" label={"Upload CSV file"} valuePropName="fileList" getValueFromEvent={normFile1} noStyle>
            <Upload.Dragger
              name="files"
              beforeUpload={file => {
                const isCSV = file.type === 'text/csv' || file.name.endsWith('.csv');
                if (!isCSV) {
                  message.error('You can only upload CSV files!');
                }
                return isCSV;
              }}
              maxCount={1}
              fileList={fileList1}
              accept=".csv"
              onChange={handleCSVUpload}
              itemRender={() => <></>}
            >
              {!fileList1.length > 0 && (
                <p className="ant-upload-drag-icon">
                  <UploadOutlined style={{ color: 'rgba(144, 144, 144, 1)' }} />
                </p>
              )}
              <p className="ant-upload-text" style={{ color: 'rgba(144, 144, 144, 1)' }}>
                {fileList1.length > 0 ? (
                  <span>
                    <span style={{ color: "rgba(16, 198, 0, 1)" }}>File Uploaded Successfully </span>
                    {fileList1[0].name}
                    <CloseCircleOutlined
                      style={{ fontSize: '16px', color: '#ff4d4f', marginLeft: '8px', cursor: 'pointer' }}
                      onClick={clearFileUpload}
                    />
                  </span>
                ) : (
                  'Click or drag file to this area to upload'
                )}
              </p>
            </Upload.Dragger>
          </Form.Item>

          <div className={`${styles.or} my-3`}>OR</div>

          <p className={styles.inputTitles}>Enter website URLs in CSV format</p>
          <Form.Item
            name="links"
            rules={[{ validator: validateLinkCount }, { validator: validateLinks }]}
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Select
                maxCount={remaining_tokens}
                mode="tags"
                style={{ width: '100%', marginBottom: "0px" }}
                placeholder={IsFileUploaded ? "File uploaded, select a new file to clear" : "Enter Links"}
                disabled={IsFileUploaded}
                onChange={setLinks}
                value={links}
                key={selectKey}
                open={false}
              />
              {!IsFileUploaded && links.length > 0 && (
                <CloseCircleOutlined
                  style={{ fontSize: '16px', color: '#ff4d4f', marginLeft: '8px', cursor: 'pointer' }}
                  onClick={clearLinks}
                />
              )}
            </div>
          </Form.Item>
          <p className={styles.note}>NOTE: Maximum allowed leads / campaign : {remaining_tokens}</p>
          <div className="row">
            <div className="col text-center">
              <Form.Item>
                <Button htmlType="submit" className={styles.submitBtn}>
                  Next Step
                </Button>
              </Form.Item>
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default WebsiteInfoForm;
