import React from "react";
import { useState, useEffect } from "react";
import { Button, Form, Upload, message, Progress, Spin, Checkbox, Input } from "antd";
import { InboxOutlined, ArrowLeftOutlined ,UploadOutlined} from "@ant-design/icons";
import styles from "./styles/NewProject.module.css";
import './styles/UploadVideo.css'

const UploadVideoForm = ({ fileList2, normFile2, setFileList2, goToFirstForm, onSecondFormFinish }) => {
    const [uploadProgress, setUploadProgress] = useState(0);
    const [loading, setLoading] = useState(false); // State to track loading
    const [showDragger, setShowDragger] = useState(true);
    const [startMinutes, setStartMinutes] = useState(0);
    const [startSeconds, setStartSeconds] = useState(0);
    const [endMinutes, setEndMinutes] = useState(0);
    const [endSeconds, setEndSeconds] = useState(0);
    const [videoDuration, setvideoDuration] = useState(0);
    const [videoMinutes, setvideoMinutes] = useState(0);
    const [videoSeconds, setvideoSeconds] = useState(0);
    const [isVideoUploading, setisVideoUploading] = useState(false);
    const [useImportedVideoLength, setuseImportedVideoLength] = useState(true);
    const [doNotUseAdditionalVideo, setdoNotUseAdditionalVideo] = useState(false);


    useEffect(()=>{
        if(!showDragger)
        {
            setFileList2('')
        }
    },[showDragger])

    useEffect(()=>{
        if(uploadProgress == 100)
        {
            setisVideoUploading(false)
        }
    },[uploadProgress])
    const beforeUpload = async (file) => {
        setisVideoUploading(true)
        // Update the file type validation to include MKV
        const isMP4OrMOVOrMKV = file.type === 'video/mp4' || file.type === 'video/quicktime' || file.type === 'video/x-matroska';
        if (!isMP4OrMOVOrMKV) {
            message.error('You can only upload MP4, MOV, or MKV files!');
            return false;
        }
    
        const isLt100MB = file.size / 1024 / 1024 < 100;
        if (!isLt100MB) {
            message.error('Video must be smaller than 100MB!');
            return false;
        }
    
        const getVideoDuration = (file) => {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = () => {
                    const video = document.createElement('video');
                    video.preload = 'metadata';
    
                    video.onloadedmetadata = () => {
                        window.URL.revokeObjectURL(video.src);
                        const duration = video.duration;
                        resolve(duration);
                    };
    
                    video.onerror = () => {
                        reject(new Error('Failed to load video'));
                    };
    
                    video.src = URL.createObjectURL(new Blob([file]));
                };
    
                reader.readAsArrayBuffer(file);
            });
        };
    
        try {
            const duration = await getVideoDuration(file);
            setvideoDuration(duration);
            if (duration > 600) {
                message.error('Video duration must be less than 10 minutes!');
                return false;
            }
        } catch (error) {
            message.error('Error loading video');
            console.error('Error getting video duration:', error);
            return false;
        }
    
        return true;
    };

    

    const handleVideoChange = ({ fileList }) => {
        setFileList2(fileList);
    };

    const onFinish = () => {
        const videoUploaded = fileList2.length > 0;
        let totalSeconds = (videoMinutes * 60) + videoSeconds;
        const secondsEntered = totalSeconds > 0 && totalSeconds <= 300;
        
        console.log(totalSeconds)
        if (!videoUploaded && !doNotUseAdditionalVideo) { //video is not uploaded and use additional video
            message.error("Please upload a video or enter the number of seconds before submitting!");
            return;
        }

        if (!secondsEntered && doNotUseAdditionalVideo) {//seconds are not entered and use additional video
            message.error("Please enter the number of seconds between 0-300 before submitting!");
            return;
        }
        if(videoUploaded && !useImportedVideoLength)
        {
            if((startMinutes*60 + startSeconds) > videoDuration || (endMinutes*60 + endSeconds) > videoDuration ){
                message.error("Range cannot be greater than video length");
                return
            }
        }
        if (videoUploaded) {
            const file = fileList2[0].originFileObj;
            const isMP4 = file.type === 'video/mp4';
            const isMOV = file.type === 'video/quicktime';
            const isMKV = file.type === 'video/x-matroska'; // Add this line
            const isLt100MB = file.size / 1024 / 1024 < 100;
    
            if (!(isMP4 || isMOV || isMKV) || !isLt100MB) {
                message.error('Please upload a valid video file (MP4, MOV, or MKV format, size < 100MB)!');
                return;
            }
        }

        let start_video_from = 0
        
        if(!useImportedVideoLength && !doNotUseAdditionalVideo){ //give custom length
            console.log("Calculate now")
            if(startMinutes == 0 && startSeconds == 0 && endMinutes == 0 && endSeconds == 0){
                message.error('Please enter valid seconds!');
                return
            }
            start_video_from = startMinutes*60 + startSeconds
            totalSeconds = Math.abs( endMinutes - startMinutes ) * 60 + Math.abs(endSeconds - startSeconds)
        }
console.log("CALLING SECOND FORM GINISH")
        onSecondFormFinish(!doNotUseAdditionalVideo ? (useImportedVideoLength ? videoDuration : totalSeconds) : totalSeconds ,start_video_from);
    };
    
    
    return (
        <div className="row justify-content-center">
            {loading && <Spin fullscreen />}
            <div className="col-10 text-start mb-4">
                <p className={styles.step}>Step 2: Upload Video</p>
                
                <Form name="uploadVideo" layout="vertical" autoComplete="off" onFinish={onFinish}>
                      <span className="poin">
                      <p className={styles.inputTitles}>Upload Video</p>
                        <Form.Item name="video" valuePropName="fileList" getValueFromEvent={normFile2} noStyle>
                            <Upload.Dragger
                                name="files"
                                beforeUpload={beforeUpload}
                                maxCount={1}
                                fileList={fileList2}
                                onChange={handleVideoChange}
                                accept=".mp4, .mov, .mkv" // Add .mkv here
                                onProgress={(event) => {
                                    setUploadProgress(Math.round((event.loaded / event.total) * 100));
                                }}
                                itemRender={()=><></>}
                                style={{ pointerEvents: doNotUseAdditionalVideo ? 'none' : 'auto', opacity: doNotUseAdditionalVideo ? 0.3 : 1 }} // Disable interactions and adjust opacity
                            >
                                {!fileList2.length > 0 && <p className="ant-upload-drag-icon"> <UploadOutlined  style={{ color: 'rgba(144, 144, 144, 1)' }}/> </p>}
                                <p className="ant-upload-text" style={{ color: 'rgba(144, 144, 144, 1)' }}> {fileList2.length > 0 ? <span><span style={{color:"rgba(16, 198, 0, 1)"}}>File Uploaded Successfully </span>{fileList2[0].name}</span> : 'Click or drag file to this area to upload'} </p>
                            </Upload.Dragger>
                        </Form.Item>
                      </span>
                      <p className={styles.note}>Note:
                    Allowed video extensions: .mp4, .mov, .mkv<br />
                    Maximum video duration allowed: 10 minutes<br />
                    Maximum video size allowed: 100 MB
                </p>
                
                    {!doNotUseAdditionalVideo &&  <>
                        <Checkbox defaultChecked onChange={(e) => setuseImportedVideoLength((e.target.checked))} style={{ margin:"20px 0px" }}>
                    Use Imported Video Length
                    </Checkbox>
                            <p className={styles.inputTitles}>Set video length</p>
                            <Form.Item  style={{ pointerEvents: useImportedVideoLength ? 'none' : 'auto', opacity: useImportedVideoLength ? 0.3 : 1 }}>
                                <div className="d-flex align-self-center">
                                    <span className="align-self-center me-3">Start video at:</span>
                                    <Input className={`${styles.input} me-2`} style={{width:"80px"}} type="number" value={startMinutes} onChange={(e) => setStartMinutes(Number(e.target.value))}/><span className="py-2 me-2">Minutes</span>
                                    <Input className={`${styles.input} me-2`} style={{width:"80px"}} type="number" value={startSeconds} onChange={(e) => setStartSeconds(Number(e.target.value))}/><span className="py-2">Seconds</span>
                                </div>
                            </Form.Item>
                            <Form.Item  style={{ pointerEvents: useImportedVideoLength ? 'none' : 'auto', opacity: useImportedVideoLength ? 0.3 : 1 }}>
                            <div className="d-flex align-self-center">
                                    <span className="align-self-center me-3">End video at:</span>
                                    <Input className={`${styles.input} me-2`} style={{width:"80px"}} type="number" value={endMinutes} onChange={(e) => setEndMinutes(Number(e.target.value))}/><span className="py-2 me-2">Minutes</span>
                                    <Input className={`${styles.input} me-2`} style={{width:"80px"}} type="number" value={endSeconds} onChange={(e) => setEndSeconds(Number(e.target.value))}/><span className="py-2">Seconds</span>
                                </div>
                            </Form.Item>
                        </>
                        }
                        <div className={`${  'or'} mb-4 w-100`}>or</div>

                    <Checkbox onChange={(e) => setdoNotUseAdditionalVideo((e.target.checked))} style={{ margin:"20px 0px" }}>
                    Do not use additional video
                    </Checkbox>
                    <p className={styles.inputTitles}>Set video length</p>
                    <Form.Item  style={{ pointerEvents: !doNotUseAdditionalVideo ? 'none' : 'auto', opacity: !doNotUseAdditionalVideo ? 0.3 : 1 }}>
                            <div className="d-flex align-self-center">
                                    <Input className={`${styles.input} me-2`} style={{width:"80px"}} type="number" value={videoMinutes} onChange={(e) => setvideoMinutes(Number(e.target.value))}/><span className="py-2 me-2">Minutes</span>
                                    <Input className={`${styles.input} me-2`} style={{width:"80px"}} type="number" value={videoSeconds} onChange={(e) => setvideoSeconds(Number(e.target.value))}/><span className="py-2">Seconds</span>
                                </div>
                            </Form.Item>
                    {uploadProgress > 0 && (
                         <div className="my-3">
                         <Progress percent={uploadProgress} className={uploadProgress<100 && 'whiteProgressText'} />
                     </div>
                    )}
                    <div className="row my-3">
                        <div className="col text-center">
                            <Form.Item>
                                <Button onClick={goToFirstForm} className={styles.previousBtn}> <ArrowLeftOutlined /> Back </Button>
                                <Button type="primary" htmlType="submit" className={styles.submitBtn} disabled={isVideoUploading}> Submit </Button>
                            </Form.Item>
                        </div>
                    </div>
                </Form>
            </div>
        </div>
    );
};

export default UploadVideoForm;