import React, { useEffect, useState } from "react";
import { Spin, message } from "antd";
import axios from "axios";
import styles from "./styles/NewProject.module.css";
import WebsiteInfoForm from "../components/WebsiteInfoForm";
import UploadVideoForm from "../components/UploadVideoForm";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { DOMAIN_NAME } from "../values/DomainName";
import { GET_VIDEOS_LEFT } from "../api/api_settings";
import { API_CREATE_PROJECT } from "../api/api_project";

const NewProject = () => {
    const navigate = useNavigate()
    const token = useSelector((state) => state.authToken.token);
    const [ShowSpinner, setShowSpinner] = useState(false);
    const [Options, setOptions] = useState([]);
    const [ShowForm1, setShowForm1] = useState(true);
    const [fileList1, setFileList1] = useState([]);
    const [fileList2, setFileList2] = useState([]);
    const [form1Values, setForm1Values] = useState({});
    const [rerenderKey, setRerenderKey] = useState(0); // State variable to trigger rerender
    const [IsFileUploaded, setIsFileUploaded] = useState(false);
    const [isVideoUploaded, setisVideoUploaded] = useState(false);

      useEffect(() => {
        const getRamainingVideos = async () => {
            const response = await GET_VIDEOS_LEFT(setShowSpinner,token)
            if (response?.data?.videos_left <= 0) {
                message.error("Video Limit exceeded!")
                navigate("/pricing");
            }
        }
        getRamainingVideos();
      }, []);
    
      
    const handleChange = (value) => {
        setOptions(value);
    };

    const normFile1 = (e) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e?.fileList;
    };

    const normFile2 = (e) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e?.fileList;
    };

    const onFirstFormFinish = (values) => {
        setForm1Values(values);
        setShowForm1(false);
        triggerRerender(); // Trigger rerender after the form is finished
    };
    
    const onSecondFormFinish = async (seconds,start_video_from) => {
        const combinedValues = {
            ...form1Values,
            web_links: Options,
        };
        // console.log('seconds:',seconds,'start_video_from',start_video_from)
        await API_CREATE_PROJECT(setShowSpinner,token,combinedValues,seconds,start_video_from,fileList2,navigate)
    };
    
    const goToFirstForm = () => {
        setShowForm1(true);
        triggerRerender(); // Trigger rerender when going back to the first form
    };

    const triggerRerender = () => {
        setRerenderKey(prevKey => prevKey + 1); // Increment key to trigger rerender
    };
    return (
        <>
            {ShowSpinner && <Spin fullscreen />}
            {/* <Navbar /> */}
            <div className={`row m-0  justify-content-center ${styles.mainRow}`}>
                <div className={`col-12 col-xl-6 col-lg-8 col-md-9 col-sm-10 p-0 align-self-center  ${styles.mainCol} text-center`}>
                    <div className="row m-0 justify-content-center text-center mt-3">
                        <div className={`d-flex col-12 col-md-10 ${styles.btnContainer}`} style={{ justifyContent: "center" }}>
                            <p className={styles.headerText} style={{ color: "rgba(0, 126, 178, 1)" }}>1. Leads &#xbb; </p>
                            <p className={styles.headerText} style={{ color: `${ShowForm1 ? "rgba(144, 144, 144, 1)" :"rgba(0, 126, 178, 1)"}` }}>2. Video &#xbb; </p>
                            <p className={styles.headerText} style={{ color: "rgba(144, 144, 144, 1)" }}>3. Design &#xbb; </p>
                            <p className={styles.headerText} style={{ color: "rgba(144, 144, 144, 1)" }}>4. Results &#xbb; </p>
                            
                        </div>
                    </div>
                    {ShowForm1 ? (
                        <WebsiteInfoForm
                            key={rerenderKey} // Key to trigger rerender in WebsiteInfoForm
                            initialValues={form1Values}
                            handleChange={handleChange}
                            normFile1={normFile1}
                            onFirstFormFinish={onFirstFormFinish}
                            setIsFileUploaded ={setIsFileUploaded}
                            IsFileUploaded ={IsFileUploaded}
                        />
                    ) : (
                        <UploadVideoForm
                            fileList2={fileList2}
                            normFile2={normFile2}
                            setFileList2={setFileList2}
                            goToFirstForm={goToFirstForm}
                            onSecondFormFinish={onSecondFormFinish}
                            setisVideoUploaded ={setisVideoUploaded}
                            isVideoUploaded ={isVideoUploaded}
                        />
                    )}
                </div>
            </div>
        </>
    );
};

export default NewProject;
