export const PRIVACY_POLICY_TEXT = `
Cyber Visionaries is committed to safeguarding your privacy.<br>
This privacy policy outlines our practices regarding the collection, use, and disclosure of personal information in compliance with the General Data Protection Regulation (GDPR) and Google’s data protection requirements.<br>
By using our site, you consent to the policies and practices described in this document.<br><br>
The security of your Personal Information is important to us, but remember that no method of transmission over the Internet, or method of electronic storage, is 100% secure.<br>
While we strive to use commercially acceptable means to protect your Personal Information, we cannot guarantee its absolute security.<br><br>
<strong>I. Information Collection And Use</strong><br>
1. While using our Site, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you. Personally identifiable information may include, but is not limited to your name (“Personal Information”), email address, phone number or other details to help you with your experience.<br>
2. We collect information from you when you register on our site, subscribe to a newsletter, respond to a survey, fill out a form, Use Live Chat or enter information on our site.<br>
3. Like many site operators, we collect information that your browser sends whenever you visit our Site (“Log Data”).<br>
4. This Log Data may include information such as your computer’s Internet Protocol (“IP”) address, browser type, browser version, the pages of our Site that you visit, the time and date of your visit, the time spent on those pages and other statistics.<br><br>
<strong>II. Communication</strong><br>
1. We may use your Personal Information to contact you with newsletters, marketing or promotional materials and other information.<br>
2. We may use the information we collect from you when you register, make a purchase, sign up for our newsletter, respond to a survey or marketing communication, surf the website, or use certain other site features in the following ways:<br>
2.1. To personalize your experience and to allow us to deliver the type of content and product offerings in which you are most interested.<br>
2.2. To improve our website in order to better serve you.<br>
2.3. To allow us to better service you in responding to your customer service requests.<br>
2.4. To administer a contest, promotion, survey or other site feature.<br>
2.5. To quickly process your transactions.<br>
2.6. To ask for ratings and reviews of services or products.<br>
2.7. To follow up with them after correspondence (live chat, email or phone inquiries).<br>
3. We keep your data (in most cases only email) in our CRM system with the above purposes. You can Unsubscribe at any time from receiving further messages from us.<br><br>
<strong>III. Cookies</strong><br>
1. Cookies are files with a small amount of data, which may include an anonymous unique identifier. Cookies are sent to your browser from a web site and stored on your computer’s hard drive. Cookies are small files that a site or its service provider transfers to your computer’s hard drive through your Web browser (if you allow) that enables the site’s or service provider’s systems to recognize your browser and capture and remember certain information. For instance, we use Google Analytics, AdWords conversion tracking cookies and Facebook Pixel to help us create more useful articles and better user experience. They are also used to help us understand your preferences based on previous or current site activity, which enables us to provide you with improved services. We also use cookies to help us compile aggregate data about site traffic and site interaction so that we can offer better site experiences and tools in the future.<br>
2. We use “cookies” to collect information. You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. If you do not want to allow the cookies, you still can use our website – just turn them off from your browser settings. You can choose to have your computer warn you each time a cookie is being sent, or you can choose to turn off all cookies. Since every browser is a little different, look at your browser’s Help Menu to learn the correct way to modify your cookies. If you choose to reject cookies, you may still use our website though your access to some functionality and areas of our website may be restricted.<br><br>
<strong>IV. Third-party disclosure</strong><br>
1. We do not sell, trade, or otherwise transfer to outside parties your personal information unless we provide users with advance notice. This does not include website hosting partners and other parties who assist us in operating our website, conducting our business, or serving our users. We may also release information when its release is appropriate to comply with the law, enforce our site policies, or protect ours or others’ rights, property or safety.<br>
2. However, non-personally identifiable visitor information may be provided to other parties for marketing, advertising, or other uses.<br>
3. Please note that if you decide to subscribe to our newsletter, we may send you information on behalf of our partners which is relevant to what you do. You can unsubscribe at any time.<br>
4. We also may include or offer third-party products or services on our website and / or newsletter and partner with trusted third parties to provide you with co-marketing content that we think may be relevant to you. However, we are not responsible for the privacy practices employed by those third-party websites or the information or content they contain. This Privacy Policy applies solely to information collected by us through the Site. Therefore, this Privacy Policy does not apply to your use of a third party website accessed by selecting a link on our Site or newsletter. The privacy policy of that other website or application will apply to your access or use of that site or application. We encourage our users to read the privacy statements of other websites before proceeding to use them.<br>
5. Our Site may include Social Media Features, such as the Facebook Like button and Widgets, such as the Share This button or interactive mini-programs that run on our site. These features may collect your IP address, which page you are visiting on our site, and may set a cookie to enable the feature to function properly. Social Media Features and Widgets are hosted by a third party. This Privacy Policy does not apply to these features. Your interactions with these features are governed by the privacy policy and other policies of the companies providing them. We have no control over these Social Media Features and therefore have no responsibility for any of the information / features contained in them.<br>
6. In addition, we may use third-party services such as:<br>
6.1. Google Analytics that collect, monitor and analyze how you interact with the Site.<br>
6.2. MailChimp for saving your email address as well as other data related to your email address. We use MailChimp as our marketing automation platform. By submitting any form on the Site, you acknowledge that the information you provide will be transferred to MailChimp for processing in accordance with their Privacy Policy and Terms.<br>
6.3. The following plug-ins:<br>
a) “Insert Headers and Footers” By WPBeginner.<br>
b) “MailChimp for WordPress” By ibericode.<br>
c) “Cookie Notice” By dFactory.<br>
d) “Yoast” SEO.<br>
e) “Contact Form 7” By Takayuki Miyoshi.<br>
f) “Disable Comments” By Samir Shah.<br>
g) “User Profile Picture” By Ronald Huereca.<br>
h) “Force HTTPS (SSL Redirect & Fix Insecure Content)” By LittleBizzy.<br>
i) “Loco Translate” By Tim Whitlock.<br>
6.4. Facebook Pixel.<br>
6.5. Google Remarketing Tag.<br>
6.6. Hotjar.<br><br>
<strong>V. Development Events</strong><br>
If we (or our assets) are acquired by another organization (company), whether by merger, acquisition, bankruptcy or otherwise, that organization would receive all information gathered by Us on the Site. In this event, you will be notified via email and/or a prominent notice on our website, of any change in ownership.<br><br>
<strong>VI. Disclaimers</strong><br>
1. We do not guarantee the continuous functioning of the Site.<br>
2. We reserve the right to interrupt the access to certain material or the entire website for an unlimited period of time, planned or accidental. We are not liable for any occurred as a result of the suspension harm to the User.<br>
3. We reserve the right to redirect the User to other websites that are controlled by us. In this case, the User has to accept the terms and conditions of those websites.<br>
4. We reserve the right to redirect the User to other websites that are owned and controlled by third parties. We have no control over the content of these websites and therefore have no responsibility for any of the information contained in these websites.<br>
5. The information found on the Site shall not in any way be interpreted as any type of advice or consultation. All User actions related to the protection of his rights and legitimate interests have to be done after due consultation with a specialist in the needed area.<br>]6. The information provided on the Site can be general and abstract. Its proper application in practice depends on the right, including legal facts of the particular situation that can not be described on the Site. This is the reason that we have no responsibility of how the User apply the information in a different, more complex situation or a situation with specifics, resulting in any kind of damage over the User.<br><br>
<strong>VII. About Us</strong><br>
cybervisionaries.com (the “Site”, “us”, “we”, or “our”). This page informs you of our policies regarding the collection, use and disclosure of Personal Information we receive from users of the Site. By using the Site, you agree to the collection and use of information in accordance with this policy. The Site is property of “See Black Sea” – a non-profit organization for public benefit registered in the European Union, Bulgaria, under ID: 177248333.<br>
If you have any questions about this Privacy Policy, please contact us by email at [admin @ cybervisionaries [dot] com]. More information on the subject of protection of personal data as well as contact detail of Data Protection Authorities can be found at https://ec.europa.eu/info/law/law-topic/data-protection_en.<br><br>
<strong>VIII. Changes To This Privacy Policy</strong><br>
1. This Privacy Policy is effective and will remain in effect except with respect to any changes in its provisions in the future, which will be in effect immediately after being posted on this page.<br>
2. We reserve the right to update or change our Privacy Policy at any time and you should check this Privacy Policy periodically. Your continued use of the Site after we post any modifications to the Privacy Policy on this page will constitute your acknowledgment of the modifications and your consent to abide and be bound by the modified Privacy Policy.<br>`
export const PAGE_TERMS = `
By accessing the website at https://wou.ai/ and https://app.wou.ai/, you are agreeing to be bound by these terms of service, all applicable laws and regulations, and agree that you are responsible for compliance with any applicable local laws. If you do not agree with any of these terms, you are prohibited from using or accessing this site. The materials contained in this website are protected by applicable trademark and copyright law.<br><br>
<strong>Your Responsibilities</strong><br>
You are responsible for your conduct, content, and communications with others while reading or downloading any information and software. You must comply with the following requirements when using our website or software:<br>
(a) You may not purchase, use, attempt to decompile or reverse engineer any materials contained on Wou AI's website and software.<br>
(b) You may not misuse our materials by interfering with their normal operation, or attempting to access them using a method other than through the interfaces and instructions that we provide.<br>
(c) You may not circumvent or attempt to circumvent any limitations that Wou AI imposes on your account.<br>
(d) Unless authorized by Wou AI in writing, you may not probe, scan, or test the vulnerability of any Wou AI system or network.<br>
(e) Unless authorized by Wou AI in writing, you may not use any manual or automated system or software to extract or scrape data from the website, our software or other interfaces through which we make our services available.<br><br>
<strong>Disclaimer of Warranty and Limitation of Liability</strong><br>
The information and software ("materials") by Wou AI are provided on an 'as is' basis. Wou AI makes no warranties of any kind, expressed or implied, including any implied warranty of merchantability or fitness for a particular purpose, or the accuracy or freedom from error, of the data or the program used by or furnished to the user. You assume the entire risk as to the quality, performance, and suitability of the software and information. This paragraph shall survive the termination of this agreement. We do not and cannot warrant that the software will operate without errors or that any or all service(s) will be available at all times. Except as specifically provided or otherwise required by law, our officers, directors, employees, agents, or contractors are not liable for any indirect, incidental, special, or consequential damages or loss of profits, revenue, data, or use by you or any third party, whether in an action in contract or tort or based on a warranty. Further, in no event, unless otherwise stated in this agreement, shall our liability exceed the amounts paid by you for the services provided to you through our website or software.<br><br>
<strong>License Termination</strong><br>
Upon violation of any stated terms or restrictions by Wou AI, your license to use our materials will be terminated. Upon termination, you must promptly destroy any downloaded materials in your possession, whether in electronic or printed format. Failure to do so will result in a fine of $10,000.<br><br>
<strong>Accuracy of materials</strong><br>
The information presented on the Wou AI website or software may contain technical, typographical, or photographic errors. Wou AI does not guarantee that any of the materials on its platform are accurate, complete, or up-to-date. While Wou AI may update the content on its platform, it is under no obligation to do so.<br><br>
<strong>Links</strong><br>
The Wou AI platform may contain links to external websites controlled or offered by third parties. These links are provided solely for your convenience. Wou AI has not vetted all linked sites and does not endorse or recommend any products, services, or information found on these sites. Wou AI is not liable for any content, products, or services offered on these third-party sites.<br><br>
<strong>Modifications</strong><br>
Wou AI reserves the right to modify these terms of service for its platform at any time without prior notice. By continuing to use the platform after such modifications, you are agreeing to the updated terms. It is recommended to regularly review the terms of service to stay informed of any changes.<br><br>
<strong>Governing Law</strong><br>
These terms and conditions are governed by the laws of the Republic of Lithuania and the European Union. By accessing and using the Wou AI platform, you irrevocably submit to the exclusive jurisdiction of the courts located in Lithuania for any disputes arising out of these terms.<br>
`;

export const PAGE_REFUND_POLICY = `
Thank you for shopping with Wou AI.<br><br>
If you are not satisfied with your purchase, we'll provide you with a full refund if you cancel within 14 days of starting your subscription. The following terms are applicable for any products that You purchased with Us.<br><br>
<strong>Interpretation and Definitions</strong><br>
The words in which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in the plural.<br><br>
<strong>Definitions</strong><br>
Company (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to Mantas Digital, MB, V. Nagevičiaus g. 3, Lithuania, Vilnius.<br>
Goods refer to the items offered for sale on the Service.<br>
Orders mean a request by You to purchase Goods from Us.<br>
Service refers to the Website.<br>
Website refers to Wou AI, accessible from wou.ai.<br>
You means the individual accessing or using the Service, or the Company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.<br><br>
<strong>Your Order Cancellation Rights</strong><br>
You are entitled to cancel Your Order within 14 days without any given reason for doing so. The deadline for canceling an Order is 14 days from the date on which You received the Goods or on which a third party you have appointed, who is not the carrier, takes possession of the product delivered.<br>
In order to exercise Your right of cancellation, You must inform Us of your decision by means of a clear statement. You can inform us of your decision by sending us an e-mail at hi@wou.ai.<br>
We will reimburse You no later than 14 days from the day on which We receive the returned Goods. We will use the original method of payment as You used for the Order, and You will not incur any fees for such reimbursement.<br><br>
<strong>Contacting us</strong><br>
If at any time you have any questions about our Refund Policy, please contact us by sending an e-mail at hi@wou.ai.<br>
`;
