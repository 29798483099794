import React from 'react';
import { useState } from 'react';
import { API_SUBMIT_HELP_FORM } from '../api/api_navigator';
import { Button, Modal, Input, Form, Row, Col } from 'antd';
import { useSelector } from 'react-redux';
import './styles/Feedbackforms.css'
const FeedbackForms = ({ isModalOpen, setIsModalOpen, typeOfModal }) => {
  const [form] = Form.useForm();
  const { token, isLoggedIn } = useSelector((state) => state.authToken);

  const handleOk = async () => {
    try {
      const values = await form.validateFields();
      console.log('Form Values:', values); // Debug log
      const data = { ...values, message_type: typeOfModal };
      await API_SUBMIT_HELP_FORM(data, token,isLoggedIn);
      setIsModalOpen(false);
    } catch (error) {
      console.error('Validation Failed:', error);
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <Modal
      closeIcon={false}
      open={isModalOpen}
      okText="Submit"
      onOk={handleOk}
      onCancel={handleCancel}
      width={800}
    >
      <h3 className='modal-heading'>
        {typeOfModal === "report" ? "Report a Bug" :
         typeOfModal === "feedback" ? "Leave Feedback" :
         typeOfModal === "contact" ? "Contact Us" :
         typeOfModal === "apply_affiliate" ? "Apply for Affiliate Program" : ""}
      </h3>
      <Form form={form} layout="vertical">
        <Row gutter={16}>
          {!isLoggedIn && (
            <Col span={24}>
              <Form.Item
                name="email"
                label="Email"
                rules={[{ required: true, message: 'Please input your email!' }]}
              >
                <Input />
              </Form.Item>
            </Col>
          )}
        </Row>
        <Row gutter={16}>
          <Col xs={24} md={12}>
            <Form.Item
              name="first_name"
              label="First Name"
              rules={[{ required: true, message: 'Please input your first name!' }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              name="last_name"
              label="Last Name"
              rules={[{ required: true, message: 'Please input your last name!' }]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          name="message"
          label="Message"
          rules={[{ required: true, message: 'Please input your message!' }]}
        >
          <Input.TextArea rows={4} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default FeedbackForms;
